import { Button, LinearProgress, TextField, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../api";
import { useAppError } from "../../components/AppState";
import { ScrollPanel, SidePanel } from "../../components/Misc";

function UpdateCourse({ courseId, onClose }) {
    const { setAppError } = useAppError();

    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [refreshToggle, setRefreshToggle] = useState(true);

    const [name, setName] = useState('');
    const [section, setSection] = useState('');
    const [descriptionHeading, setDescriptionHeading] = useState('');
    const [description, setDescription] = useState('');
    const [room, setRoom] = useState('');

    useEffect(() => {
        if (course !== null) {
            setName(course.name);
            setSection(course.section);
            setDescriptionHeading(course.descriptionHeading);
            setDescription(course.description);
            setRoom(course.room);
        }
    }, [course])

    useEffect(() => {
        if (courseId !== null) {
            setCourse(null);
            setLoading(true);
            api.getCourseDetails(courseId).then(response => {
                setCourse(response.data);
                setLoading(false);
            }, () => {
                setLoading(false);
                setAppError("Failed getting Classroom Details.");
            });
        } else {
            setUpdated(false);
        }
    }, [courseId, refreshToggle, setAppError]);

    const handleUpdateClick = () => {
        const data = {
            name,
            section,
            descriptionHeading,
            description,
            room
        };

        setLoading(true);
        api.updateCourseDetails(courseId, data).then(_ => {
            setLoading(false);
            setUpdated(true);
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Updating classroom details failed.");
        });
    }

    const hasChanges = (course !== null) && ((name !== course.name) || (section !== course.section) || (descriptionHeading !== course.descriptionHeading) || (description !== course.description) || (room !== course.room));

    return (
        <>
            {courseId != null &&
                <SidePanel title={"Classroom Details"} onClose={() => onClose(updated)} >
                    <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '16px 16px 16px 24px', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <Tooltip title="Classroom Id">
                                <div style={{ display: 'inline-block', padding: '4px 16px', borderRadius: '8px', color: '#fff', backgroundColor: '#557' }}>{courseId}</div>
                            </Tooltip>
                        </div>
                        <div>
                            <Button variant='contained' disabled={!hasChanges} color='primary' onClick={handleUpdateClick}>Save</Button>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading && <LinearProgress />}
                        {!loading &&
                            <ScrollPanel>
                                {course !== null &&
                                    <div style={{ padding: '0px 16px' }}>
                                        <TextField required label="Name" value={name} onChange={x => setName(x.target.value)} margin="normal" fullWidth />
                                        <TextField label="Section" value={section} onChange={x => setSection(x.target.value)} margin="normal" fullWidth />
                                        <TextField label="Description heading" value={descriptionHeading} onChange={x => setDescriptionHeading(x.target.value)} margin="normal" fullWidth />
                                        <TextField label="Description" value={description} onChange={x => setDescription(x.target.value)} margin="normal" fullWidth />
                                        <TextField label="Room" value={room} onChange={x => setRoom(x.target.value)} margin="normal" fullWidth />
                                    </div>
                                }
                            </ScrollPanel>
                        }
                    </div>
                </SidePanel>
            }
        </>
    )
}

export default UpdateCourse;
