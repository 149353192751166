import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    error: {
        paddingTop: '8px',
        paddingBottom: '16px',
        borderBottom: '1px solid #eee',
        '&:hover .fade': {
            opacity: 1
        },
        '&:hover': {
            backgroundColor: '#eee'
        },
    },
}))

function ErrorListDialog({ title, errors, onClose }) {
    const classes = useStyles();

    return (
        <Dialog open={errors !== null} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <div style={{ height: '400px', overflowY: 'auto' }}>
                    {errors !== null && errors.map((x, i) =>
                        <div key={i} className={classes.error}>
                            {x}
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorListDialog;
