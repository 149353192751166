import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../api";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ScrollPanel } from "../../components/Misc";
import {useAppError} from "../../components/AppState";


const useStyles = makeStyles((theme) => ({
    userItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:not(:hover) .end-buttons': {
            display: 'none'
        },
        '&:hover .end-buttons': {
            display: 'block',
            paddingRight:'16px'
        }
    },
}));

function AddSystemUser({ open, onClose, onAdd }) {

    const [email, setEmail] = useState('');

    useEffect(() => {
        if (open) {
            setEmail('');
        }
    }, [open]);


    const handleAdd = () => {
        onClose();
        onAdd(email);
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">Add system user</DialogTitle>
            <DialogContent>
                <TextField margin="dense" label="Email" value={email} onChange={x => setEmail(x.target.value)} fullWidth required autoFocus />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">Cancel</Button>
                <Button onClick={handleAdd} color="primary" disabled={email === ''} >Add</Button>
            </DialogActions>
        </Dialog>
    )
}

function ConfirmRemoveSystemUser({ user, onClose, onConfirm }) {

    const handleConfirm = () => {
        onClose();
        onConfirm(user);
    }

    return (
        <Dialog open={user !== null} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">Remove system user?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="default">Cancel</Button>
                <Button onClick={handleConfirm} color="primary">Remove</Button>
            </DialogActions>
        </Dialog>
    )
}


function SystemUsers() {

    const classes = useStyles();
    const { setAppError } = useAppError();

    const [users, setUsers] = useState([]);
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [confirmRemoveUser, setConfirmRemoveUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshToggle, setRefreshToggle] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.getSystemUsers().then(x => {
            setUsers(x.data);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting System Users.");
        });

    }, [refreshToggle, setAppError]);

    const handleAdd = (email) => {
        setLoading(true);
        api.addSystemUser(email).then(x => {
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Failed adding System User.");
        });
    }

    const handleRemove = (user) => {
        setLoading(true);
        api.removeSystemUser(user.id).then(x => {
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Failed removing System User");
        });
    }

    return (
        <>
            <AddSystemUser open={addUserOpen} onClose={() => setAddUserOpen(false)} onAdd={handleAdd} />
            <ConfirmRemoveSystemUser user={confirmRemoveUser} onClose={() => setConfirmRemoveUser(null)} onConfirm={handleRemove} />

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', margin: '16px' }}>
                    <div style={{ flex: 1 }}>
                    </div>
                    <div>
                        <Button color="primary" variant='contained' onClick={() => setAddUserOpen(true)}>Add user</Button>
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    {loading && <LinearProgress />}
                    {!loading &&
                        <ScrollPanel>
                            {users.map(x =>
                                <div key={x.id} className={classes.userItem}>
                                    <div style={{ padding: '16px', flex: 1 }}>
                                        {x.email}
                                    </div>
                                    <div className='end-buttons'>
                                        <Tooltip title="Remove" placement="left-start">
                                            <IconButton onClick={() => setConfirmRemoveUser(x)}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        </ScrollPanel>
                    }
                </div>
            </div>
        </>
    )
}

export default SystemUsers;
