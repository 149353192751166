import { useEffect, useState } from "react";
import SelectUsersDialog from "../../components/SelectUsersDialog";
import api from "../../api";
import { executeChanges } from "../../misc";
import LoadingDialog from "../../components/LoadingDialog";
import ErrorListDialog from "../../components/ErrorListDialog";


function AddTeachers({ courses, onClose }) {

    const [applyingChanges, setApplyingChanges] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (courses !== null) {
            setApplyingChanges(false);
            setErrors(null);
        }
    }, [courses]);

    const handleApplyChanges = (courses, users) => {
        setApplyingChanges(true);

        const changes = [];
        for (let i = 0; i < courses.length; i++) {
            for (let j = 0; j < users.length; j++) {
                const course = courses[i];
                const user = users[j];
                changes.push({
                    getPromise: () => api.addTeacher(course.id, user.id),
                    failedText: `Adding teacher ${user.name} (${user.email}) to course ${course.name} failed.`
                });
            }
        }

        executeChanges(changes, 3).onComplete.then(x => {
            setApplyingChanges(false);
            const failed = x.filter(x => x.result.failed === true).map(x => x.change.failedText);
            if (failed.length !== 0) {
                setErrors(failed);
            }
        });
    }

    return (
        <>
            <SelectUsersDialog title="Add teachers" buttonText="Add" open={courses !== null} onClose={onClose} onUsersSelected={x => handleApplyChanges(courses, x)} />
            <LoadingDialog open={applyingChanges} title="Adding teachers" />
            <ErrorListDialog title="Completed with errors" errors={errors} onClose={() => setErrors(null)} />
        </>
    )
}

export default AddTeachers;
