import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Popover } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useEffect, useRef, useState } from "react";
import api from "./api";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAccount, useAppError, useImpersonateCustomer } from "./components/AppState";

const useStyles = makeStyles((theme) => ({

    account: {
        width: '200px',
        height: '40px',
        padding: '8px 16px',
        border: '1px solid #eee',
        borderRadius: '4px',
        display: 'flex',
        userSelect: 'none',
        cursor: 'pointer',
        fontWeight: '500',
        '& .MuiAvatar-root': {
            height: '20px',
            width: '20px'
        },
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    accountLoading: {
        width: '200px',
        height: '40px',
        padding: '8px 16px',
        border: '1px solid #eee',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        fontWeight: '500'
    }
}));


function Account() {
    const { setAppError } = useAppError();
    const { account, setAccount } = useAccount();
    const { impersonateCustomer, setImpersonateCustomer } = useImpersonateCustomer();

    const classes = useStyles();
    const accountRef = useRef(null);
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [accountLoading, setAccountLoading] = useState(false);
    const [signinError, setSigninError] = useState(null);

    const [gsiClientInitialized, setGsiClientInitialized] = useState(false);
    const [gsiCredential, setGsiCredential] = useState(null);



    useEffect(() => {
        const initializeGsiClient = () => {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_CLIENT_ID,
                auto_select: "true",
                callback: x => setGsiCredential(x.credential)
            });
            setGsiClientInitialized(true);
        }

        const exitingScript = document.getElementById("gsi-client");
        if (exitingScript) {
            initializeGsiClient();
            return;
        }

        const script = document.createElement("script");
        script.id = "gsi-client";
        script.async = true;
        script.src = "https://accounts.google.com/gsi/client";
        script.onload = initializeGsiClient;
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        setAccountLoading(true);
        const impersonateCustomerId = impersonateCustomer === null ? null : impersonateCustomer.id;
        api.onCredentialChange(gsiCredential, impersonateCustomerId, () => setGsiCredential(null)).then(x => {
            setAccountLoading(false);
            setAccount(x);
        }, () => {
            setAccountLoading(false);
            setAppError("Failed getting Account.");
        });
    }, [gsiCredential, impersonateCustomer, setAccount, setAppError]);

    const googleButtonRef = useCallback(node => {
        if (node === null || gsiClientInitialized === false) {
            return;
        }

        window.google.accounts.id.renderButton(
            node,
            {
                type: "standard",
                shape: "rectangular",
                theme: "outline",
                text: "signin_with",
                size: "large",
                logo_alignment: "left"
            }
        );
    }, [gsiClientInitialized]);

    const handleSignOut = () => {
        setGsiCredential(null);
        window.google.accounts.id.disableAutoSelect();
    }

    return (
        <>
            <Dialog open={signinError !== null} onClose={() => setSigninError(null)} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">SignIn Error</DialogTitle>
                <DialogContent>
                    {signinError}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSigninError(null)} color="primary">Ok</Button>
                </DialogActions>
            </Dialog>

            <div style={{ display: 'flex' }}>
                {impersonateCustomer &&
                    <div style={{ alignSelf: 'center', marginRight: '48px' }}>
                        <Button variant="contained" color="primary" startIcon={<ExitToAppIcon />} onClick={() => setImpersonateCustomer(null)}>Stop viewing customer {impersonateCustomer.name}</Button>
                    </div>
                }
                <div ref={accountRef}>
                    {accountLoading === true &&
                        <div className={classes.accountLoading}>
                            <div style={{ flex: 1 }}>
                                Loading Account
                            </div>
                            <LinearProgress />
                        </div>
                    }
                    {accountLoading === false && account === null &&
                        <div ref={googleButtonRef}></div>
                    }
                    {accountLoading === false && account !== null &&
                        <div className={classes.account} onClick={() => setUserMenuOpen(true)}>
                            <div style={{ flex: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{account.name}</div>
                            <Avatar />
                        </div>
                    }
                </div>
            </div>

            <Popover
                id="user-menu"
                open={userMenuOpen}
                anchorEl={accountRef.current}
                onClose={() => setUserMenuOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            >
                <Paper >
                    <List>
                        <ListItem button onClick={() => { setUserMenuOpen(false); handleSignOut(); }}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sign out" />
                        </ListItem>
                    </List>
                </Paper>
            </Popover>
        </>
    )
}

export default Account;
