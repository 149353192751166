import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        position: 'fixed',
        inset: '80px 0px 0px 130px',
        zIndex: 900,
        backgroundColor: 'rgb(0 0 0 / 10%)',
        display: 'flex'
    },
    title: {
        flex: '1',
        fontSize: '1.1rem',
        '& span': {
            fontWeight: '500',
            color: '#555'
        }
    },
    user: {
        padding: '8px 16px 8px 16px',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover .fade': {
            opacity: 1
        },
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:not(:hover) .end-buttons': {
            display: 'none'
        },
        '&:hover .end-buttons': {
            display: 'block'
        }
    },
    userName: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: '#555'
    }

}));




function ConfirmUsersDialog({ users, title, buttonText, onClose, onConfirm }) {

    const classes = useStyles();

    const handleOkClick = () => {
        onClose();
        onConfirm(users);
    }

    return (
        <Dialog open={users !== null} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent >
                <div style={{ height: '360px', overflowX: 'auto' }}>
                    {users !== null && users.map(user =>
                        <div key={user.id} className={classes.user} style={{ display: 'flex', }}>
                            <div style={{ flex: 1, paddingTop: '8px' }}>
                                <div className={classes.userName}>{user.name}</div>
                                <div>{user.email}</div>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">Cancel</Button>
                <Button onClick={handleOkClick} color="primary">{buttonText}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmUsersDialog;
