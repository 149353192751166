import { useEffect, useState } from "react";
import { executeChanges } from "../../misc";
import api from "../../api";
import CourseParticipantsPanel from "./CourseParticipantsPanel";
import ConfirmUsersDialog from "../../components/ConfirmUsersDialog";
import ErrorListDialog from "../../components/ErrorListDialog";
import LoadingDialog from "../../components/LoadingDialog";
import SelectUsersDialog from "../../components/SelectUsersDialog";
import { useAppError } from "../../components/AppState";

function CourseTeachers({ course, onClose }) {
    const { setAppError } = useAppError();

    const [teachers, setTeachers] = useState([]);
    const [addTeachersOpen, setAddTeachersOpen] = useState(false);
    const [teachersToRemove, setTeachersToRemove] = useState(null);
    const [applyingChanges, setApplyingChanges] = useState(false);
    const [applyingChangesTitle, setApplyingChangesTitle] = useState('');
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [refreshToggle, setRefreshToggle] = useState(true);

    useEffect(() => {
        if (course === null) {
            return;
        }
        setTeachers([]);
        setTeachersToRemove(null);
        setLoading(true);
        api.getCourseTeachers(course.id).then(response => {
            setTeachers(response.data);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Classsroom Teachers.");
        });

    }, [course, refreshToggle, setAppError]);


    const handleAddTeachers = (users) => {
        setApplyingChanges(true);
        setApplyingChangesTitle('Adding teachers');

        const changes = users.map(x => ({
            getPromise: () => api.addTeacher(course.id, x.id),
            failedText: `Adding teacher ${x.name} (${x.email}) failed.`
        }));

        executeChanges(changes, 3).onComplete.then(x => {
            setApplyingChanges(false);
            setRefreshToggle(x => !x);
            const failed = x.filter(x => x.result.failed === true).map(x => x.change.failedText);
            if (failed.length !== 0) {
                setErrors(failed);
            }
        });
    }

    const handleRemoveTeachers = (users) => {
        setApplyingChanges(true);
        setApplyingChangesTitle('Removing teachers');

        const changes = users.map(x => ({
            getPromise: () => api.removeTeacher(course.id, x.id),
            failedText: `Removing teacher ${x.name} (${x.email}) failed.`
        }));

        executeChanges(changes, 3).onComplete.then(x => {
            setApplyingChanges(false);
            setRefreshToggle(x => !x);
            const failed = x.filter(x => x.result.failed === true).map(x => x.change.failedText);
            if (failed.length !== 0) {
                setErrors(failed);
            }
        });
    }

    return (
        <>
            {course !== null &&
                <>
                    <SelectUsersDialog title="Add teachers" buttonText="Add" open={addTeachersOpen} onClose={() => setAddTeachersOpen(false)} onUsersSelected={handleAddTeachers} />
                    <ConfirmUsersDialog title="Remove teachers" buttonText="Remove" users={teachersToRemove} onClose={() => setTeachersToRemove(null)} onConfirm={handleRemoveTeachers} />
                    <LoadingDialog open={applyingChanges} title={applyingChangesTitle} />
                    <ErrorListDialog title="Completed with errors" errors={errors} onClose={() => setErrors(null)} />

                    <CourseParticipantsPanel
                        title={<div style={{ fontSize: '1.1rem' }}>Teachers of <span style={{ fontWeight: '500', color: '#555' }}>{course.name}</span></div>}
                        noUsersText="No teachers."
                        loading={loading}
                        users={teachers}
                        onAddOpen={() => setAddTeachersOpen(true)}
                        onRemove={users => setTeachersToRemove(users)}
                        onClose={onClose} />
                </>
            }
        </>
    )
}

export default CourseTeachers;
