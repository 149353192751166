import { Button, Checkbox, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItem, MenuItem, Paper, Popover, Select, TextField, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import { useCallback, useEffect, useRef, useState } from 'react';
import api from "../../../api";
import { makeStyles } from '@material-ui/styles';
import { NoContent, ScrollPanel } from '../../../components/Misc';
import { useAppError } from '../../../components/AppState';

const useStyles = makeStyles((theme) => ({
    courseItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee',
            borderBottom: '1px solid #555'
        }
    }
}));


function CourseFilters({ onChange }) {

    const debounceTimerRef = useRef(null);
    const [nameValue, setNameValue] = useState('');
    const [name, setName] = useState('');
    const [courseState, setCourseState] = useState('ANY');
    const [assignedState, setAssignedState] = useState('any');
    const [domain, setDomain] = useState('any');
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        let filters = {}
        if (name !== '') {
            filters.name = name;
        }
        if (courseState !== 'ANY') {
            filters.courseState = courseState;
        }
        if (assignedState !== 'any') {
            filters.assignedState = assignedState;
        }
        if (domain !== 'any') {
            filters.domain = domain;
        }
        onChange(filters);
    }, [name, courseState, assignedState, domain, onChange]);

    useEffect(() => {
        api.getDomains().then(x => {
            setDomains(x.data);
        }, () => { })
    }, []);

    const handleNameValueChange = (x) => {
        setNameValue(x);
        clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = setTimeout(() => setName(x), 300);
    }


    return (
        <div>
            <div style={{ marginBottom: '24px' }}>
                <TextField fullWidth label="Classroom Name"
                    value={nameValue}
                    onChange={x => handleNameValueChange(x.target.value)}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                {nameValue !== '' &&
                                    <IconButton onClick={e => { e.stopPropagation(); setNameValue(''); setName(''); }}>
                                        <ClearIcon />
                                    </IconButton>
                                }
                            </InputAdornment>
                    }}
                />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <FormControl fullWidth>
                    <InputLabel>Classroom State</InputLabel>
                    <Select value={courseState} onChange={x => setCourseState(x.target.value)}>
                        <MenuItem value={'ANY'}>Any</MenuItem>
                        <MenuItem value={'ACTIVE'}>Active</MenuItem>
                        <MenuItem value={'ARCHIVED'}>Archived</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth>
                    <InputLabel>Assigned State</InputLabel>
                    <Select value={assignedState} onChange={x => setAssignedState(x.target.value)}>
                        <MenuItem value={'any'}>Any</MenuItem>
                        <MenuItem value={'false'}>Not Assigned</MenuItem>
                        <MenuItem value={'true'}>Assigned</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {(domains.length > 2) &&
                <div>
                    <FormControl fullWidth>
                        <InputLabel>Domain</InputLabel>
                        <Select value={domain} onChange={x => setDomain(x.target.value)}>
                            <MenuItem value={'any'}>Any</MenuItem>
                            {domains.map((x, i) =>
                                <MenuItem key={i} value={x}>{x}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            }
        </div>
    )
}




function SectionCourseAssignments({ section, onBack }) {

    const { setAppError } = useAppError();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [allCourseCount, setAllCourseCount] = useState(0);
    const [selectedCourseIds, setSelectedCourseIds] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const pageCount = Math.ceil(allCourseCount / pageSize);
    const [filters, setFilters] = useState(null);
    const [refreshToggle, setRefreshToggle] = useState(false);

    const pageSizeMenuAnchorRef = useRef(null);
    const [pageSizeMenuOpen, setPageSizeMenuOpen] = useState(false);


    useEffect(() => {
        if (section === null) {
            return;
        }

        const params = {
            ...filters,
            offset: (pageNumber - 1) * pageSize,
            limit: pageSize
        };

        setLoading(true);
        api.getSectionCourses(section.id, params).then(x => {
            const c = x.data.courses;
            setCourses(c);
            setAllCourseCount(x.data.allCount);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Classrooms Assignments.");
        });
    }, [section, filters, pageNumber, pageSize, refreshToggle, setAppError]);

    const handleFilterChange = useCallback(x => {
        setFilters(x);
        setPageNumber(1);
    }, [setFilters, setPageNumber])

    const handleSelectAllClick = () => {
        if (allSelected) {
            setSelectedCourseIds([]);
        } else {
            setSelectedCourseIds(courses.map(x => x.id));
        }
    }

    const handleSelectCourse = (course, checked) => {
        if (checked) {
            setSelectedCourseIds(c => [...c, course.id]);
        } else {
            setSelectedCourseIds(c => c.filter(x => x !== course.id));
        }
    }

    const handleAssignCourses = (x) => {
        setLoading(true);
        setSelectedCourseIds([]);
        api.assignSectionCourses(section.id, x).then(x => {
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Failed assigning Classrooms.");
        });
    }

    const handleUnassignCourses = (x) => {
        setLoading(true);
        setSelectedCourseIds([]);
        api.unassingSectionCourses(section.id, x).then(x => {
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Failed unassigning Classrooms.");
        });
    }

    const canAssign = section !== null && section.type === "Manual";

    const allSelected = courses.length !== 0 && courses.every(x => selectedCourseIds.includes(x.id));

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ flex: '1', fontSize: '1.1rem', paddingLeft: '16px' }}>
                    Classroom asignment
                    <span style={{ paddingLeft: '24px', fontWeight: '700' }}>{section.name}</span>
                </div>
                <div>
                    <Button variant='contained' color='primary' startIcon={<ChevronLeftIcon />} onClick={onBack}>Back to Sections</Button>
                </div>
            </div>

            <div style={{ flex: 1, display: 'flex' }} >
                <div style={{ width: '300px', marginTop: '8px', marginRight: '16px', paddingRight: '16px', borderRight: '1px solid #eee' }}>
                    <CourseFilters onChange={handleFilterChange} />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '52px', paddingBottom: '8px', borderBottom: '1px solid #eee' }}>
                        {canAssign &&
                            <>
                                <div>
                                    <Tooltip title="Select all" placement="bottom">
                                        <Checkbox checked={allSelected} onClick={() => handleSelectAllClick()} color="primary" />
                                    </Tooltip>
                                </div>
                                {selectedCourseIds.length !== 0 &&
                                    <div style={{ marginLeft: '24px' }}>
                                        <Tooltip title="Assign selected courses" placement="bottom">
                                            <IconButton onClick={() => handleAssignCourses(selectedCourseIds)}>
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Unassign selected courses" placement="bottom">
                                            <IconButton onClick={() => handleUnassignCourses(selectedCourseIds)}>
                                                <IndeterminateCheckBoxIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                            </>
                        }
                        
                        <div style={{ flex: 1 }}></div>
                        <div style={{ width: '150px' }}>
                            Assigned
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading &&
                            <LinearProgress />
                        }
                        {!loading && courses.length === 0 &&
                            <NoContent text="No Items" />
                        }
                        {!loading && courses.length !== 0 &&
                            <ScrollPanel>
                                {courses.map(x =>
                                    <div key={x.id} className={classes.courseItem}>
                                        {canAssign &&
                                            <div>
                                                <Checkbox color="primary" checked={selectedCourseIds.includes(x.id)} onChange={e => handleSelectCourse(x, e.target.checked)} />
                                            </div>
                                        }
                                        {!canAssign &&
                                            <div style={{ height: '42px', width: '42px' }}></div>
                                        }
                                        <div style={{ flex: 1 }}>{x.name}</div>
                                        <div style={{ width: '110px' }}>
                                            {x.assigned && <CheckCircleIcon />}
                                        </div>
                                    </div>
                                )}
                            </ScrollPanel>
                        }
                    </div>

                    <div style={{ borderTop: '1px solid #ccc', paddingTop: '8px', display: 'flex', height: '50px', alignItems: 'center' }} >
                        <div>Items per page:</div>
                        <div ref={pageSizeMenuAnchorRef}>
                            <Button onClick={() => setPageSizeMenuOpen(true)}><span style={{ fontWeight: 400 }}>{pageSize}</span><ArrowDropDownIcon /></Button>
                        </div>
                        <Popover
                            id="page-size-menu"
                            open={pageSizeMenuOpen}
                            anchorEl={pageSizeMenuAnchorRef.current}
                            onClose={() => { setPageSizeMenuOpen(false) }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                            transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                        >
                            <Paper >
                                <List>
                                    {[10, 20, 50, 100].map((x, index) =>
                                        <ListItem key={index} button onClick={() => { setPageSizeMenuOpen(false); setPageSize(x); }}>{x}</ListItem>
                                    )}
                                </List>
                            </Paper>
                        </Popover>
                        <div style={{ flex: 1 }}></div>

                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(1)}><FirstPageIcon /></IconButton>
                        <div>Page {pageNumber} of {pageCount}</div>
                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><NavigateBeforeIcon /></IconButton>
                        <IconButton disabled={pageNumber + 1 > pageCount} onClick={() => setPageNumber(pageNumber + 1)}><NavigateNextIcon /></IconButton>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionCourseAssignments;
