import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../api";
import ErrorListDialog from "../../components/ErrorListDialog";
import LoadingDialog from "../../components/LoadingDialog";
import { executeChanges } from "../../misc";

const useStyles = makeStyles((theme) => ({
    course: {
        padding: '8px 16px 8px 16px',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        },
    },
    courseName: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: '#555'
    },
    courseSection: {
        color: '#888'
    }

}));

function ArchiveCourse({ courses, onClose, onArchived }) {
    const classes = useStyles();

    const [applyingChanges, setApplyingChanges] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (courses !== null) {
            setApplyingChanges(false);
            setErrors(null);
        }
    }, [courses]);

    const handleArchiveClicked = () => {
        onClose();
        setApplyingChanges(true);

        const changes = courses.map(x => ({
            getPromise: () => api.archiveCourse(x.id),
            failedText: `Archiving course ${x.name} failed.`
        }));

        executeChanges(changes, 3).onComplete.then(x => {
            setApplyingChanges(false);
            onArchived();
            const failed = x.filter(x => x.result.failed === true).map(x => x.change.failedText);
            if (failed.length !== 0) {
                setErrors(failed);
            }
        });
    }

    return (
        <>
            <Dialog open={courses !== null} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Archive Classrooms</DialogTitle>
                <DialogContent >
                    <div style={{ height: '360px', overflowX: 'auto' }}>
                        {courses !== null && courses.map(x =>
                            <div key={x.id} className={classes.course}>
                                <div style={{ paddingTop: '8px' }}>
                                    <div className={classes.courseName}>{x.name}</div>
                                    <div className={classes.courseSection}>{x.section === '' ? <>&nbsp;</> : x.section}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="default">Cancel</Button>
                    <Button onClick={handleArchiveClicked} color="primary">Archive</Button>
                </DialogActions>
            </Dialog>

            <LoadingDialog open={applyingChanges} title="Archiving classrooms" />
            <ErrorListDialog title="Completed with errors" errors={errors} onClose={() => setErrors(null)} />
        </>
    )
}

export default ArchiveCourse;
