import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import UserSelect from "./UserSelect";
import ClearIcon from '@material-ui/icons/Clear';
import GroupSelect from "./GroupSelect";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        position: 'fixed',
        inset: '80px 0px 0px 130px',
        zIndex: 900,
        backgroundColor: 'rgb(0 0 0 / 10%)',
        display: 'flex'
    },
    title: {
        flex: '1',
        fontSize: '1.1rem',
        '& span': {
            fontWeight: '500',
            color: '#555'
        }
    },
    user: {
        padding: '8px 16px 8px 16px',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover .fade': {
            opacity: 1
        },
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:not(:hover) .end-buttons': {
            display: 'none'
        },
        '&:hover .end-buttons': {
            display: 'block'
        }
    },
    userName: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: '#555'
    }

}));

function SelectUsersDialog({ open, title, buttonText, onClose, onUsersSelected }) {

    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [byGroup, setByGroup] = useState(false);

    useEffect(() => {
        if (open) {
            setUsers([]);
            setByGroup(false);
        }
    }, [open]);

    const handleAddUser = (user) => {
        if (!users.some(x => x.id === user.id)) {
            setUsers(u => [...u, user]);
        }
    }

    const handleRemoveUser = (user) => {
        setUsers(oldUsers => oldUsers.filter(u => u.id !== user.id));
    }

    const handleOkClick = () => {
        onClose();
        onUsersSelected(users);
    }

    const handleGroupSelected = (group) => {
        let newUsers = [...users, ...group.users.filter(x => !users.some(u => u.id === x.id))];
        setUsers(newUsers);
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <div style={{ marginBottom: '8px', display: 'flex' }}>
                    {byGroup === false &&
                        <>
                            <div style={{ flex: 1 }}>
                                <UserSelect label={'Search for user'} user={null} onUserSelected={handleAddUser} />
                            </div>
                            <Button variant="outlined" color="primary" onClick={() => setByGroup(true)}>
                                <SwapHorizIcon />
                                <div style={{ marginLeft: '8px', width: '140px' }}>Switch to groups</div>
                            </Button>
                        </>
                    }
                    {byGroup === true &&
                        <>
                            <div style={{ flex: 1 }}>
                                <GroupSelect label={'Search for group'} getMembers onGroupSelected={handleGroupSelected} />
                            </div>
                            <Button variant="outlined" color="primary" onClick={() => setByGroup(false)}>
                                <SwapHorizIcon />
                                <div style={{ marginLeft: '8px', width: '140px' }}>Switch to users</div>
                            </Button>
                        </>
                    }
                </div>
                <div style={{ height: '360px', overflowX: 'auto' }}>
                    {users.map(user =>
                        <div key={user.id} className={classes.user} style={{ display: 'flex', }}>
                            <div style={{ flex: 1, paddingTop: '8px' }}>
                                <div className={classes.userName}>{user.name}</div>
                                <div>{user.email}</div>
                            </div>
                            <div className="end-buttons">
                                <Tooltip title="Remove" placement="left-start">
                                    <IconButton onClick={() => handleRemoveUser(user)}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">Cancel</Button>
                <Button onClick={handleOkClick} color="primary" disabled={users.length === 0} >{buttonText}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SelectUsersDialog;
