import { Button, LinearProgress } from "@material-ui/core";
import { format, formatDistanceStrict } from "date-fns";
import { useEffect, useState } from "react";
import api from "../../api";
import { useAppError } from "../../components/AppState";


function CacheEntry({ name, lastUpdatedAt, startedAt, onClick }) {

    let lastUpdated = null;
    let lastUpdatedDistance = 'never updated';
    let started = null;
    let startedDistance = null;

    if (lastUpdatedAt) {
        let date = new Date(lastUpdatedAt);
        lastUpdated = `Updated at ${format(date, "yyy-MM-dd HH:mm")}`;
        lastUpdatedDistance = `updated ${formatDistanceStrict(date, new Date())} ago`;
    }

    if (startedAt) {
        let date = new Date(startedAt);
        started = `Update in progress, started at ${format(date, "yyy-MM-dd HH:mm")}`;
        startedDistance = `update started ${formatDistanceStrict(date, new Date())} ago`;
    }

    return <>
        <div style={{ display: 'flex', marginTop: '8px', height: '48px', alignItems: 'center', borderTop: '1px solid #eee', fontWeight: '500' }}>
            <div style={{ flex: 1 }}>
                <span title={lastUpdated}>{name} - {lastUpdatedDistance}</span>{ }</div>
            <div >
                {startedAt &&
                    <>
                        <span title={started}>{startedDistance}</span>
                    </>
                }
                {!startedAt &&
                    <>
                        <Button variant='contained' color='primary' onClick={onClick}>Update now</Button>
                    </>
                }
            </div>
        </div>
    </>
}

function Misc() {
    const { setAppError } = useAppError();

    const [loading, setLoading] = useState(false);
    const [cacheState, setCacheState] = useState(null);
    const [refreshToggle, setRefreshToggle] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.getCacheState().then(x => {
            setCacheState(x.data);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Cache State.");
        });
    }, [refreshToggle, setAppError]);


    const handleUpdateCourses = () => {
        api.updateCoursesCache().then(() => {
            setRefreshToggle(x => !x);
        }, () => {
            setAppError("Failed starting Classrooms Update.");
        });
    }

    const handleUpdateUsers = () => {
        api.updateUsersCache().then(() => {
            setRefreshToggle(x => !x);
        }, () => {
            setAppError("Failed starting Users Update.");
        });
    }

    const handleUpdateGroups = () => {
        api.updateGroupsCache().then(() => {
            setRefreshToggle(x => !x);
        }, () => {
            setAppError("Failed starting Groups Update.");
        });
    }

    return (
        <div style={{ padding: '16px', borderBottom: '1px solid #eee' }}>
            <div style={{ fontSize: '1.1rem', marginBottom: '16px' }}>Cached Google data</div>
            <div>To improve usability, Classroom Manager saves frequently accessed Google data.</div>
            <div>This data is automatically updated regularly, but in some cases it might not be up to date.</div>
            <div>Here you can manually start the update for different Google data.</div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}></div>


            </div>
            {loading &&
                <LinearProgress />
            }
            {!loading && cacheState &&
                <div>
                    <CacheEntry name="Classroms" lastUpdatedAt={cacheState.coursesLastUpdatedAt} startedAt={cacheState.coursesUpdateStartedAt} onClick={handleUpdateCourses} />
                    <CacheEntry name="Users" lastUpdatedAt={cacheState.usersLastUpdatedAt} startedAt={cacheState.usersUpdateStartedAt} onClick={handleUpdateUsers} />
                    <CacheEntry name="Groups" lastUpdatedAt={cacheState.groupsLastUpdatedAt} startedAt={cacheState.groupsUpdateStartedAt} onClick={handleUpdateGroups} />
                </div>
            }
        </div>
    )
}

export default Misc;
