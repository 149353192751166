import { Checkbox, IconButton, LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { NoContent, ScrollPanel, SidePanel } from "../../components/Misc";


const useStyles = makeStyles((theme) => ({
    user: {
        padding: '8px 16px 8px 16px',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover .fade': {
            opacity: 1
        },
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:not(:hover) .end-buttons': {
            display: 'none'
        },
        '&:hover .end-buttons': {
            display: 'block'
        }
    },
    userName: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: '#555'
    }
}));


function CourseParticipantsPanel({ title, noUsersText, loading, users, onAddOpen, onRemove, onClose }) {

    const classes = useStyles();
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    useEffect(() => {
        setSelectedUserIds([]);
    }, [users]);

    const handleCheckUser = (checked, user) => {
        if (checked) {
            setSelectedUserIds([...selectedUserIds, user.id]);
        } else {
            setSelectedUserIds(selectedUserIds.filter(x => x !== user.id));
        }
    }

    const handleCheckAll = () => {
        if (allSelected) {
            setSelectedUserIds([]);
        } else {
            setSelectedUserIds(users.map(x => x.id));
        }
    }

    const allSelected = users.length !== 0 && users.every(x => selectedUserIds.includes(x.id));

    return (
        <>
            <SidePanel title={title} onClose={onClose}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '16px' }}>
                        <div style={{ width: '64px', alignSelf: 'center' }}>
                            <Tooltip title="Select all" placement="left-start">
                                <Checkbox checked={allSelected} onClick={() => handleCheckAll()} color="primary" />
                            </Tooltip>
                        </div>
                        <div style={{ flex: '1' }}>
                            {selectedUserIds.length !== 0 &&
                                <Tooltip title="Remove" placement="left-start">
                                    <IconButton onClick={() => onRemove(users.filter(u => selectedUserIds.some(x => x === u.id)))}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        <div>
                            <Tooltip title="Add" placement="left-start">
                                <IconButton onClick={() => onAddOpen()}>
                                    <GroupAddIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading && <LinearProgress />}
                        {!loading && users.length === 0 && <NoContent text={noUsersText} />}
                        {!loading &&
                            <ScrollPanel>
                                {users.map(user =>
                                    <div key={user.id} className={classes.user} style={{ display: 'flex', }}>
                                        <div className={selectedUserIds.includes(user.id) ? "" : "fade"} style={{ width: '64px' }}>
                                            <Checkbox checked={selectedUserIds.includes(user.id)} onChange={(x) => handleCheckUser(x.target.checked, user)} color="primary" />
                                        </div>
                                        <div style={{ flex: 1, paddingTop: '8px' }}>
                                            <div className={classes.userName}>{user.name}</div>
                                            <div>{user.email}</div>
                                        </div>
                                        <div className="end-buttons">
                                            <Tooltip title="Remove" placement="left-start">
                                                <IconButton onClick={() => onRemove([user])}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                            </ScrollPanel>
                        }
                    </div>
                </div>
            </SidePanel>
        </>
    )
}

export default CourseParticipantsPanel;
