import { Button, FormControl, IconButton, InputLabel, LinearProgress, makeStyles, MenuItem, Select } from "@material-ui/core";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useEffect, useRef, useState } from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import api from "../../api";
import { useAppError } from "../../components/AppState";
import { NoContent, ScrollPanel } from "../../components/Misc";
import { executeChanges } from "../../misc";


const useStyles = makeStyles((theme) => ({
    changesItem: {
        padding: '8px',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        },
    }
}));

function FormatAndDownloadTemplateButton({ onClick }) {
    const [templateFormat, setTemplateFormat] = useState('csv');
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '130px', marginRight: '16px', marginTop: '-16px' }}>
                <FormControl fullWidth>
                    <InputLabel >Template format</InputLabel>
                    <Select value={templateFormat} onChange={x => setTemplateFormat(x.target.value)}>
                        <MenuItem value={'csv'}>csv</MenuItem>
                        <MenuItem value={'xlsx'}>xlsx</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Button variant='contained' startIcon={<GetAppIcon />} onClick={() => onClick(templateFormat)} >Download template</Button>
        </div>
    );
}

function ImportInit({ onChanges }) {

    const { setAppError } = useAppError();
    const bulkAddInputRef = useRef(null);
    const syncInputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const downloadBulkAddStudentsTemplate = (format) => {
        api.getBulkAddStudentsTemplate({ format }).then(x => {
            window.location = `api/files/${x.data.token}/${x.data.name}`;
        }, () => {
            setAppError("Failed downloading template");
        });
    }

    const downloadSyncCourseParticipantsTemplate = (format) => {
        api.getSyncCourseParticipantsTemplate({ format }).then(x => {
            window.location = `api/files/${x.data.token}/${x.data.name}`;
        }, () => {
            setAppError("Failed downloading template");
        });
    }

    const handleBulkAddStudentsFileSelected = (file) => {
        setLoading(true);
        api.generateBulkAddStudentsChanges(file).then(x => {
            setLoading(false);
            onChanges(x.data);
        }, () => {
            setLoading(false);
            setAppError("Failed getting bulk add students changes.");
        });
    }

    const handleSynCourseParticipantsFileSelected = (file) => {
        setLoading(true);
        api.generateSyncCourseParticipantsChanges(file).then(x => {
            setLoading(false);
            onChanges(x.data);

        }, () => {
            setLoading(false);
            setAppError("Failed getting sync classroom participants changes.");
        });
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {loading &&
                <>
                    <div style={{ marginBottom: '16px' }}>
                        <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Generating changes</div>
                    </div>
                    <LinearProgress />
                </>
            }
            {!loading &&
                <>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: '16px' }}>
                            <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Bulk add student participants</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            Add a large number of students to one or more classrooms by uploading a classroom / student list in either csv or xlsx format.<br></br>
                            Students can be added to classrooms.<br></br>
                            A template file can be downloaded using the buttons below.
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <FormatAndDownloadTemplateButton onClick={(x) => downloadBulkAddStudentsTemplate(x)} />
                            </div>
                            <div style={{ flex: 1 }}></div>
                            <div>
                                <input ref={bulkAddInputRef} accept="*.csv,*.xlsx" type="file" style={{ display: 'none' }} onChange={x => handleBulkAddStudentsFileSelected(x.target.files[0])} />
                                <Button color="primary" variant='contained' startIcon={<FolderOpenIcon />} onClick={() => bulkAddInputRef.current.click()} >Upload file</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #aaa', margin: '16px' }}>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: '16px' }}>
                            <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Synchronize classroom participants</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            Update one or more classroom's participants (teachers and students) by uploading a full participant list in either csv or xlxs format.<br></br>
                            Participants in the file that are not in the classroom will be added. <br></br>
                            Participants in the classromm that are not in the file will be removed. <br></br>
                            A file containing the full participant list of one or more classrooms can be generated from the Export section and used as a starting point. <br></br>
                            A template file can be downloaded using the buttons below.
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <FormatAndDownloadTemplateButton onClick={(x) => downloadSyncCourseParticipantsTemplate(x)} />
                            </div>
                            <div style={{ flex: 1 }}></div>
                            <div>
                                <input ref={syncInputRef} accept="*.csv,*.xlsx" type="file" style={{ display: 'none' }} onChange={x => handleSynCourseParticipantsFileSelected(x.target.files[0])} />
                                <Button color="primary" variant='contained' startIcon={<FolderOpenIcon />} onClick={() => syncInputRef.current.click()} >Upload file</Button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

function ImportChanges({ changes, onDone }) {

    const [preview, setPreview] = useState(true);
    const [done, setDone] = useState(false);
    const [errors, setErrors] = useState([]);
    const [completedCount, setCompletedCount] = useState(0);
    const cancelApplyChanges = useRef(null);

    useEffect(() => {
        setPreview(true);
        setDone(false);
        setErrors([])
        setCompletedCount(0);
    }, [changes]);

    useEffect(() => {
        return () => {
            cancelApplyChanges.current && cancelApplyChanges.current();
        }
    }, []);

    const handleDoChanges = () => {
        setPreview(false);
        const changesToApply = [];
        for (let i = 0; i < changes.length; i++) {
            const c = changes[i];
            switch (c.type) {
                case 'addTeacher':
                    changesToApply.push({
                        getPromise: () => api.addTeacher(c.course.id, c.user.id),
                        failedText: `Failed to add teacher ${c.user.name} (${c.user.email}) to classroom ${c.course.name}.`
                    });
                    break;
                case 'removeTeacher':
                    changesToApply.push({
                        getPromise: () => api.removeTeacher(c.course.id, c.user.id),
                        failedText: `Failed to remove teacher ${c.user.name} (${c.user.email}) from classroom ${c.course.name}.`
                    });
                    break;
                case 'addStudent':
                    changesToApply.push({
                        getPromise: () => api.addStudent(c.course.id, c.user.id),
                        failedText: `Failed to add student ${c.user.name} (${c.user.email}) to classroom ${c.course.name}.`
                    });
                    break;
                case 'removeStudent':
                    changesToApply.push({
                        getPromise: () => api.removeStudent(c.course.id, c.user.id),
                        failedText: `Failed to remove student ${c.user.name} (${c.user.email}) from course ${c.course.name}.`
                    });
                    break;
                default:
                    break;
            }
        }

        const onProgress = (item) => {
            setCompletedCount(x => x + 1);
            if (item.result.failed === true) {
                setErrors(x => [...x, item.change.failedText]);
            }
        }

        const { onComplete, cancel } = executeChanges(changesToApply, 5, onProgress)
        cancelApplyChanges.current = cancel;

        onComplete.then(x => {
            setDone(true);
        });
    }

    const handleCancel = () => {
        cancelApplyChanges.current && cancelApplyChanges.current();
        setDone(true);
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {preview &&
                <PreviewChanges changes={changes} onDoChanges={handleDoChanges} onCancel={onDone} />
            }

            {!preview &&
                <>
                    <div style={{ marginBottom: '16px' }}>
                        <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Applying changes</div>
                    </div>
                    <div>
                        Changes done: {completedCount}&nbsp;of&nbsp;{changes.length}
                    </div>
                    <div style={{ height: '4px', marginBottom: '16px' }}>
                        {!done &&
                            <LinearProgress />
                        }
                    </div>
                    <div style={{ flex: 1 }} >
                        <ScrollPanel>
                            {errors.map((x, i) =>
                                <div key={i}>{x}</div>
                            )}
                        </ScrollPanel>
                    </div>
                    <div style={{ display: 'flex', marginTop: '16px' }}>
                        {!done &&
                            <Button variant='contained' onClick={handleCancel} >Cancel</Button>
                        }
                        <div style={{ flex: 1 }} />
                        {done &&
                            <Button color="primary" variant='contained' endIcon={<ChevronRightIcon />} onClick={onDone} >Done</Button>
                        }
                    </div>
                </>
            }
        </div>
    )
}


function ActionItem({ type }) {

    const [actionName, setActionName] = useState('');

    useEffect(() => {
        switch (type) {
            case 'addTeacher':
                setActionName('Add teacher');
                break;
            case 'removeTeacher':
                setActionName('Remove teacher');
                break;
            case 'addStudent':
                setActionName('Add student');
                break;
            case 'removeStudent':
                setActionName('Remove student');
                break;
            default:
                break;
        }
    }, [type]);

    return (
        <>
            {actionName}
        </>
    )
}


function PreviewChanges({ changes, onDoChanges, onCancel }) {

    const classes = useStyles();
    const [changesPage, setChangesPage] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const changesPerPage = 50;

    useEffect(() => {
        const offset = (pageNumber - 1) * changesPerPage;
        setChangesPage(changes.slice(offset, offset + changesPerPage));
    }, [changes, pageNumber]);

    const pageCount = Math.ceil(changes.length / changesPerPage);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '16px' }}>
                <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Changes</div>
            </div>

            {changes.length === 0 &&
                <NoContent text={"No changes."} />
            }
            {changes.length !== 0 &&
                <>
                    <div style={{ display: 'flex', padding: '8px', borderBottom: '1px solid #eee' }}>
                        <div style={{ flex: 2 }}>Classroom</div>
                        <div style={{ flex: 1 }}>Action</div>
                        <div style={{ flex: 2 }}>User</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <ScrollPanel>
                            {changesPage.map((x, i) =>
                                <div key={i} style={{ display: 'flex' }} className={classes.changesItem}>
                                    <div style={{ flex: 2 }}>
                                        {x.course.name}
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <ActionItem type={x.type} />
                                    </div>
                                    <div style={{ flex: 2 }}>
                                        {x.user.email}
                                    </div>
                                </div>
                            )}
                        </ScrollPanel>
                    </div>
                    <div style={{ borderTop: '1px solid #ccc', paddingTop: '8px', display: 'flex', height: '50px', alignItems: 'center' }} >
                        <div style={{ flex: 1 }}></div>
                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(1)}><FirstPageIcon /></IconButton>
                        <div>Page {pageNumber} of {pageCount}</div>
                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><NavigateBeforeIcon /></IconButton>
                        <IconButton disabled={pageNumber + 1 > pageCount} onClick={() => setPageNumber(pageNumber + 1)}><NavigateNextIcon /></IconButton>
                    </div>
                </>
            }
            <div style={{ display: 'flex', marginTop: '16px' }}>
                <Button variant='contained' onClick={onCancel} >Cancel</Button>
                <div style={{ flex: 1 }} ></div>
                {changes.length !== 0 &&
                    <Button color="primary" variant='contained' endIcon={<ChevronRightIcon />} onClick={onDoChanges} >Apply changes</Button>
                }
            </div>
        </div>
    )
}


function Import() {
    const [changes, setChanges] = useState(null);

    return (
        <>
            {changes === null &&
                <ImportInit onChanges={setChanges} />
            }
            {changes !== null &&
                <ImportChanges changes={changes} onDone={() => { setChanges(null) }} />
            }
        </>
    );
}

export default Import;
