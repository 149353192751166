import { Dialog, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";

function LoadingDialog({ open, title }) {
    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent style={{ width: '600px', paddingBottom: '32px' }}>
                <LinearProgress />
            </DialogContent>
        </Dialog>
    )
}

export default LoadingDialog;
