import { makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    tabButtonSelected: {
        padding: '16px',
        color: '#fff',
        backgroundColor: '#002c64',
        fontWeight: '500'
    },
    tabButton: {
        cursor: 'pointer',
        padding: '16px',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
}));

function SideTabs({ children }) {

    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        if (children.length > 0) {
            setActiveTab(children[0]);
        }
    }, [children]);

    return (
        <div style={{ flex: 1, display: 'flex', margin: '16px' }}>
            <div style={{ width: '300px', marginRight: '16px' }}>
                <div className="paper">
                    {children.map(x =>
                        <Fragment key={x.props.tabId}>
                            {activeTab === x &&
                                <div className={classes.tabButtonSelected}>
                                    <span>{x.props.tabLabel}</span>
                                </div>
                            }
                            {activeTab !== x &&
                                <div onClick={() => setActiveTab(x)} className={classes.tabButton}>
                                    <span>{x.props.tabLabel}</span>
                                </div>
                            }
                        </Fragment>
                    )}
                </div>
            </div>
            <div className="paper" style={{ flex: 1 }}>
                {activeTab}
            </div>
        </div>
    );
}

export default SideTabs;
