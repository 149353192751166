import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import App from './App';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { AppState } from "./components/AppState";

const theme = createTheme({
    palette: {
        background: {
            default: "#EDF2F7"
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    height: '100%',
                },
                body: {
                    height: '100%',
                },
            },
        },
        MuiButton: {
            root: {
              textTransform: 'none',
            },
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <AppState>
                        <App />
                    </AppState>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
