import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const apiInstance = {
    credential: null,
    impersonateCustomerId: null,
    signInResult: null,
    signInResultPromise: null,
    onSignInError: null,

    getConfig: () => { },

    get: (url, params) => apiInstance.getToken().then(x => axios.get(url, { headers: { Authorization: `Bearer ${x}` }, params })),
    post: (url, data) => apiInstance.getToken().then(x => axios.post(url, data, { headers: { Authorization: `Bearer ${x}` } })),
    postFile: (url, file) => apiInstance.getToken().then(x => {
        const data = new FormData();
        data.append('file', file);
        return axios.post(url, data, { headers: { Authorization: `Bearer ${x}`, ContentType: 'multipart/form-data' } });
    }),

    getSignInResult: () => {
        if (apiInstance.credential === null) {
            return Promise.reject();
        }
        if (apiInstance.signInResultPromise !== null) {
            return apiInstance.signInResultPromise;
        }
        if (apiInstance.signInResult !== null && apiInstance.signInResult.tokenValidUntil > Date.now()) {
            return Promise.resolve(apiInstance.signInResult);
        }

        const body = new FormData();
        body.append('googleCredential', apiInstance.credential);
        if (apiInstance.impersonateCustomerId) {
            body.append('impersonateCustomerId', apiInstance.impersonateCustomerId);
        }

        apiInstance.signInResultPromise = axios.post('auth/signin', body).then(x => {
            apiInstance.signInResult = {
                account: x.data.account,
                token: x.data.token,
                tokenValidUntil: Date.now() + ((x.data.tokenExpiresIn - 60) * 1000)
            };
            apiInstance.signInResultPromise = null;

            return apiInstance.signInResult;
        });
        if (apiInstance.onSignInError !== null) {
            apiInstance.signInResultPromise.catch(apiInstance.onSignInError);
        }
        return apiInstance.signInResultPromise;
    },

    getToken: () => {
        return apiInstance.getSignInResult().then(x => x.token);
    }
}

const api = {
    onCredentialChange: (credential, impersonateCustomerId, onSignInError) => {
        apiInstance.credential = credential;
        apiInstance.impersonateCustomerId = impersonateCustomerId;
        apiInstance.signInResult = null;
        apiInstance.signInResultPromise = null;
        apiInstance.onSignInError = onSignInError;

        return apiInstance.getSignInResult().then(x => x.account, () => null);
    },

    getAppSettings: () => apiInstance.get('settings'),
    updateAppSettings: (s) => apiInstance.post('settings', s),

    searchCourses: (params) => apiInstance.post('courses/search', params),
    getCourses: (params) => apiInstance.get('courses', params),
    getCourse: (courseId) => apiInstance.get(`courses/${courseId}`),
    getCourseOwner: (courseId) => apiInstance.get(`courses/${courseId}/owner`),
    getCourseTeachers: (courseId) => apiInstance.get(`courses/${courseId}/teachers`),
    getCourseStudents: (courseId) => apiInstance.get(`courses/${courseId}/students`),
    getCourseDetails: (courseId) => apiInstance.get(`courses/${courseId}/details`),

    getUsers: (params) => apiInstance.get('users', params),
    getGroups: (params) => apiInstance.get('groups', params),
    getGroupMembers: (groupId) => apiInstance.get(`groups/${groupId}/members`),

    addTeacher: (courseId, userId) => apiInstance.post(`courses/${courseId}/addTeacher`, { id: userId }),
    removeTeacher: (courseId, userId) => apiInstance.post(`courses/${courseId}/removeTeacher`, { id: userId }),
    addStudent: (courseId, userId) => apiInstance.post(`courses/${courseId}/addStudent`, { id: userId }),
    removeStudent: (courseId, userId) => apiInstance.post(`courses/${courseId}/removeStudent`, { id: userId }),

    createCourse: (data) => apiInstance.post('courses', data),
    updateCourseDetails: (courseId, data) => apiInstance.post(`courses/${courseId}/details`, data),
    updateCourseOwner: (courseId, userId) => apiInstance.post(`courses/${courseId}/updateOwner`, { id: userId }),
    archiveCourse: (courseId) => apiInstance.post(`courses/${courseId}/archive`),
    unarchiveCourse: (courseId) => apiInstance.post(`courses/${courseId}/unarchive`),
    deleteCourse: (courseId) => apiInstance.post(`courses/${courseId}/delete`),
    lookupCourseId: (x) => apiInstance.get('courses/lookupCourseId', { value: x }),

    getAuditRecords: (filters) => apiInstance.get('auditRecords', filters),

    getAppUsers: () => apiInstance.get('admin/appusers'),
    addAppUser: (user) => apiInstance.post('admin/appUsers', user),
    removeAppUser: (userId) => apiInstance.post(`admin/appUsers/${userId}/remove`),
    getAppUserPermissions: (userId) => apiInstance.get(`admin/appUsers/${userId}/permissions`),
    updateAppUserPermissions: (userId, permissions) => apiInstance.post(`admin/appUsers/${userId}/permissions`, permissions),
    getAppUserSections: (userId) => apiInstance.get(`admin/appUsers/${userId}/sections`),
    updateAppUserSections: (userId, sections) => apiInstance.post(`admin/appUsers/${userId}/sections`, sections),

    getCacheState: () => apiInstance.get('admin/cachedItems'),
    updateCoursesCache: () => apiInstance.post('admin/cachedItems/updateCourses'),
    updateUsersCache: () => apiInstance.post('admin/cachedItems/updateUsers'),
    updateGroupsCache: () => apiInstance.post('admin/cachedItems/updateGroups'),
    getSections: () => apiInstance.get('admin/sections'),
    getSection: (id) => apiInstance.get(`admin/sections/${id}`),
    addSection: (section) => apiInstance.post('admin/sections', section),
    updateSection: (section) => apiInstance.post(`admin/sections/${section.id}`, section),
    removeSection: (id) => apiInstance.post(`admin/sections/${id}/remove`),
    getEdulifeOrganizations: () => apiInstance.get('admin/edulifeData/organizations'),
    getSectionCourses: (id, params) => apiInstance.get(`admin/sections/${id}/courses`, params),
    assignSectionCourses: (id, data) => apiInstance.post(`admin/sections/${id}/courses/assign`, data),
    unassingSectionCourses: (id, data) => apiInstance.post(`admin/sections/${id}/courses/unassign`, data),
    getDomains: () => apiInstance.get('domains'),

    getCustomers: (filters) => apiInstance.get('system/customers', filters),
    addCustomer: (customer) => apiInstance.post('system/customers', customer),
    updateCustomer: (customer) => apiInstance.post(`system/customers/${customer.id}`, customer),
    getCustomerEdulifeConnectionIds: (customerId) => apiInstance.get(`system/customers/${customerId}/edulifeConnectionIds`),
    updateCustomerEdulifeConnectionIds: (customerId, data) => apiInstance.post(`system/customers/${customerId}/edulifeConnectionIds`, data),
    getSystemUsers: () => apiInstance.get('system/users'),
    addSystemUser: (email) => apiInstance.post('system/users', { email }),
    removeSystemUser: (userId) => apiInstance.post(`system/users/${userId}/remove`),
    getApiScopes: () => apiInstance.get('system/apiScopes'),

    getBulkAddStudentsTemplate: (params) => apiInstance.get('import/bulkAddStudents/template', params),
    generateBulkAddStudentsChanges: (file) => apiInstance.postFile('import/bulkAddStudents', file),
    getSyncCourseParticipantsTemplate: (params) => apiInstance.get('import/syncCourseParticipants/template', params),
    generateSyncCourseParticipantsChanges: (file) => apiInstance.postFile('import/syncCourseParticipants', file), 

    generateCourseDetailsExport: (data) => apiInstance.post('export/courseDetails', data),
    generateCourseParticipantExport: (data) => apiInstance.post('export/courseParticipants', data),
}

export default api;
