import { Button, IconButton, List, ListItem, makeStyles, Paper, Popover } from "@material-ui/core";
import { useRef, useState } from "react";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


const useStyles = makeStyles((theme) => ({
    pagingBar: {
        borderTop: '1px solid #ccc',
        padding: '16px',
        display: 'flex',
        height: '50px',
        alignItems: 'center',
    }
}));


function PagingBar({ pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange }) {

    const classes = useStyles();

    const [pageSizeMenuOpen, setPageSizeMenuOpen] = useState(false);
    const pageSizeMenuAnchorRef = useRef(null);

    return (
        <div className={classes.pagingBar} >
            <div>Items per page:</div>
            <div ref={pageSizeMenuAnchorRef}>
                <Button onClick={() => setPageSizeMenuOpen(true)}><span style={{ fontWeight: 400 }}>{pageSize}</span><ArrowDropDownIcon /></Button>
            </div>
            <Popover
                id="page-size-menu"
                open={pageSizeMenuOpen}
                anchorEl={pageSizeMenuAnchorRef.current}
                onClose={() => { setPageSizeMenuOpen(false) }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            >
                <Paper >
                    <List>
                        {[10, 20, 50, 100].map((x, index) =>
                            <ListItem key={index} button onClick={() => { setPageSizeMenuOpen(false); onPageSizeChange(x); }}>{x}</ListItem>
                        )}
                    </List>
                </Paper>
            </Popover>
            <div style={{ flex: 1 }}></div>
            {pageCount !== 0 &&
                <>
                    <IconButton disabled={pageNumber === 1} onClick={() => onPageNumberChange(1)}><FirstPageIcon /></IconButton>
                    <div>Page {pageNumber} of {pageCount}</div>
                    <IconButton disabled={pageNumber === 1} onClick={() => onPageNumberChange(pageNumber - 1)}><NavigateBeforeIcon /></IconButton>
                    <IconButton disabled={pageNumber + 1 > pageCount} onClick={() => onPageNumberChange(pageNumber + 1)}><NavigateNextIcon /></IconButton>
                </>
            }
        </div>
    )
}

export default PagingBar;
