import { Button, Dialog, DialogActions, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, makeStyles, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import { useEffect, useState } from 'react';
import api from "../../../api";
import SectionCourseAssignments from './SectionCourseAssignment';
import { NoContent, ScrollPanel, SidePanel } from '../../../components/Misc';
import { useAppError } from '../../../components/AppState';

const useStyles = makeStyles((theme) => ({
    sectionItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        minHeight: '50px',
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:not(:hover) .end-buttons': {
            display: 'none'
        },
        '&:hover .end-buttons': {
            display: 'block'
        }
    },
    userFilterItem: {
        display: 'flex',
        padding: '8px 16px 8px 24px',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        fontWeight: '500',
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
}));



function CreateSection({ organizations, open, onClose, onCreated }) {
    const { setAppError } = useAppError();
    const [name, setName] = useState('');
    const [orgUnitPath, setOrgUnitPath] = useState('');
    const [organizationId, setOrganizationId] = useState('');
    const [type, setType] = useState('Manual');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setName('');
            setType('Manual');
            setLoading(false);
        }
    }, [open]);

    const handleCreate = () => {
        setLoading(true);
        api.addSection({ name, type, orgUnitPath, organizationId }).then(() => {
            onClose();
            onCreated();
        }, () => {
            setLoading(false);
            setAppError("Failed creating Section.");
        });
    }

    const handleOrganizationSelected = (x) => {
        setOrganizationId(x);
        setName(organizations.find(o => o.id === x).name);
    }

    const createDisabled = (name === '') || (type === 'AutoOrgUnits' && orgUnitPath === '') || (type === 'AutoEdulifeData' && organizationId === '');

    return (
        <>
            {open &&
                <SidePanel title={<div style={{ fontSize: '1.1rem' }}>Create section</div>} onClose={() => onClose()} >
                    {loading &&
                        <LinearProgress />
                    }
                    {!loading &&
                        <>
                            <div style={{ flex: 1 }}>
                                <ScrollPanel>
                                    <div style={{ padding: '0px 24px' }}>
                                        <TextField margin="normal" label="Name" value={name} onChange={x => setName(x.target.value)} fullWidth required autoFocus />
                                        <FormControl margin="normal" fullWidth >
                                            <InputLabel>Classroom assignment</InputLabel>
                                            <Select value={type} onChange={x => setType(x.target.value)}>
                                                <MenuItem value="Manual">Manual</MenuItem>
                                                <MenuItem value="AutoOrgUnits">Automatic based on OrgUnits</MenuItem>
                                                {organizations.length !== 0 &&
                                                    <MenuItem value="AutoEdulifeData">Automatic based on Edulife Organization</MenuItem>
                                                }
                                            </Select>
                                        </FormControl>
                                        {type === "AutoOrgUnits" &&
                                            <TextField margin="normal" label="OrgUnit path" value={orgUnitPath} onChange={x => setOrgUnitPath(x.target.value)} fullWidth required />
                                        }
                                        {type === "AutoEdulifeData" &&
                                            <FormControl margin="normal" fullWidth >
                                                <InputLabel>Organization</InputLabel>
                                                <Select value={organizationId} onChange={x => handleOrganizationSelected(x.target.value)}>
                                                    {organizations.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        }
                                    </div>
                                </ScrollPanel>
                            </div>
                            <div style={{ display: 'flex', padding: '16px' }}>
                                <div style={{ flex: 1 }}>
                                </div>
                                <div>
                                    <Button variant='contained' disabled={createDisabled} color='primary' onClick={() => handleCreate()}>Create</Button>
                                </div>
                            </div>
                        </>
                    }
                </SidePanel>
            }
        </>
    )
}


function RemoveSection({ section, onClose, onConfirm }) {

    const handleConfirm = () => {
        onClose();
        onConfirm(section);
    }

    return (
        <Dialog open={section !== null} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">Remove section?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="default">Cancel</Button>
                <Button onClick={handleConfirm} color="primary">Remove</Button>
            </DialogActions>
        </Dialog>
    )
}


function Sections() {

    const { setAppError } = useAppError();
    const classes = useStyles();

    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(false);

    const [edulifeOrganizations, setEdulifeOrganizations] = useState([]);

    const [courseAssignmentSection, setCourseAssignmentSection] = useState(null);
    const [createSectionOpen, setCreateSectionOpen] = useState(false);
    const [removeSection, setRemoveSection] = useState(null);

    const [refreshToggle, setRefreshToggle] = useState(false);


    useEffect(() => {
        setLoading(true);
        api.getSections().then(x => {
            setSections(x.data);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Sections.");
        });
    }, [refreshToggle, setAppError]);

    useEffect(() => {
        api.getEdulifeOrganizations().then(x => {
            setEdulifeOrganizations(x.data);
        })
    })

    const handleRemoveSection = (x) => {
        setLoading(true);
        api.removeSection(x.id).then(() => {
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Failed removing Section.");
        });
    }

    return (
        <>
            <CreateSection organizations={edulifeOrganizations} open={createSectionOpen} onClose={() => setCreateSectionOpen(false)} onCreated={() => setRefreshToggle(x => !x)} />
            <RemoveSection section={removeSection} onClose={() => setRemoveSection(null)} onConfirm={handleRemoveSection} />

            {courseAssignmentSection &&
                <SectionCourseAssignments section={courseAssignmentSection} onBack={() => setCourseAssignmentSection(null)} />
            }
            {!courseAssignmentSection &&
                <>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px', borderBottom: '1px solid #eee' }}>
                        <div style={{ flex: '1', fontSize: '1.1rem', paddingLeft: '16px' }}>
                            Sections
                        </div>
                        <div>
                            <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setCreateSectionOpen(true)}>Create Section</Button>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading &&
                            <LinearProgress />
                        }
                        {!loading && sections.length === 0 &&
                            <NoContent text="No sections found." />
                        }
                        {!loading && sections.length !== 0 &&
                            <ScrollPanel>
                                {sections.map(x =>
                                    <div key={x.id} className={classes.sectionItem}>
                                        <div style={{ flex: 1, marginLeft: '16px' }}>{x.name}</div>
                                        <div className='end-buttons' style={{ marginRight: '8px' }}>
                                            <Tooltip title="Classroom assignment" placement="left-start">
                                                <IconButton onClick={() => setCourseAssignmentSection(x)}>
                                                    <ListIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Remove" placement="left-start">
                                                <IconButton onClick={() => setRemoveSection(x)}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                            </ScrollPanel>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Sections;
