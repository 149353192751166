import { Button, Checkbox, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Popover, Portal, Tooltip, Typography } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CourseFilters from "./CourseFilters";
import api from "../../api";
import { markTokens } from "../../misc";
import { format } from 'date-fns';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ListIcon from '@material-ui/icons/List';
import LaunchIcon from '@material-ui/icons/Launch';
import PersonIcon from '@material-ui/icons/Person';
import ArchiveIcon from '@material-ui/icons/Archive';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ChangeOwner from "./ChangeOwner";
import ArchiveCourse from "./ArchiveCourse";
import UpdateCourse from "./UpdateCourse";
import AddStudents from "./AddStudents";
import AddTeachers from "./AddTeachers";
import DeleteCourse from "./DeleteCourse";
import UnarchiveCourse from "./UnarchiveCourse";
import CourseStudents from "./CourseStudents";
import CourseTeachers from "./CourseTeachers";
import RemoveStudents from "./RemoveStudents";
import RemoveTeachers from "./RemoveTeachers";
import CreateCourse from "./CreateCourse";
import { useAppError, useCourseListColumns, useCourseListParams } from "../../components/AppState";
import CoursesTable from "./CoursesTable";
import PagingBar from "./PagingBar.js";


const useStyles = makeStyles((theme) => ({
    underline: {
        height: '3px',
        width: '35px',
        backgroundColor: '#00214C',
    },
    searchButtons: {
        background: '#FFF',
        boxShadow: 'none'
    },
    pager: {
        background: '#fff',
        borderRadius: '2px',
    },
    filterNoHighlight: {
        whiteSpace: 'pre'
    },
    filterHighlight: {
        whiteSpace: 'pre',
        fontWeight: "bold"
    },
    buttonBar: {
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        height: '52px',
        borderBottom: '2px solid #ddd'
    },
    courseTable: {
        tableLayout: 'fixed',
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        '& td': {
            padding: '0px',
        },
        '& tr': {
            borderTop: '1px solid #bbb'
        },
        '& tr:hover': {
            borderBottom: '1px solid #555'
        }
    },

    pagingBar: {
        borderTop: '1px solid #ccc',
        paddingTop: '8px',
        display: 'flex',
        height: '52px',
        alignItems: 'center',
    }
}));


function ColumnSelection({ columns, onColumnsChange }) {
    const buttonRef = useRef(null);
    const [open, setOpen] = useState(false);

    const columnNames = useMemo(() => [
        { id: "name", name: "Name", disabled: true },
        { id: "section", name: "Section" },
        { id: "created", name: "Created" },
        { id: "updated", name: "Updated" },
        { id: "ownerEmail", name: "Owner email" },
        { id: "ownerOrgUnitPath", name: "Owner OrgUnit" },
    ], []);

    const handleToggle = (id) => {
        if (columns.includes(id)) {
            onColumnsChange(columns.filter(x => x !== id))
        } else {
            onColumnsChange([...columns, id])
        }
    }

    return (
        <>
            <Tooltip title="Columns" placement="bottom">
                <IconButton ref={buttonRef} onClick={() => setOpen(true)}><MoreHorizIcon /></IconButton>
            </Tooltip>

            <Popover
                anchorEl={buttonRef.current}
                keepMounted
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            >
                <Paper>
                    <List dense>
                        {columnNames.map(x =>
                            <ListItem key={x.id} button disabled={x.disabled} onClick={() => handleToggle(x.id)}>
                                <ListItemIcon>
                                    <Checkbox color="primary" disabled={x.disabled} checked={columns.includes(x.id)} tabIndex={-1} disableRipple />
                                </ListItemIcon>
                                <ListItemText primary={x.name} />
                            </ListItem>
                        )}
                    </List>
                </Paper>
            </Popover>
        </>
    )
}

function Classrooms({ titleRef }) {
    const { setAppError } = useAppError();
    const { params, setCourseIdFilter, setPageNumber, setPageSize } = useCourseListParams();
    const { columns, sorting, setColumns, setSorting } = useCourseListColumns();

    const classes = useStyles();

    const [refreshToggle, setRefreshToggle] = useState(true);

    const [createCourseOpen, setCreateCourseOpen] = useState(false);
    const [changeOwnerCourseId, setChangeOwnerCourseId] = useState(null);
    const [archiveCourses, setArchiveCourses] = useState(null);
    const [updateCourseId, setUpdateCourseId] = useState(null);
    const [addTeachersCourses, setAddTeachersCourses] = useState(null);
    const [addStudentsCourses, setAddStudentsCourses] = useState(null);
    const [removeTeachersCourses, setRemoveTeachersCourses] = useState(null);
    const [removeStudentsCourses, setRemoveStudentsCourses] = useState(null);
    const [unarchiveCourses, setUnarchiveCourses] = useState(null);
    const [deleteCourses, setDeleteCourses] = useState(null);

    const [manageTeachersCourse, setManageTeachersCourse] = useState(null);
    const [manageStudentsCourse, setManageStudentsCourse] = useState(null);

    const [courses, setCourses] = useState([]);
    const [allCourseCount, setAllCourseCount] = useState(0);
    const [selectedCourseIds, setSelectedCourseIds] = useState([]);
    const [loading, setLoading] = useState(false);

    const [actionMenu, setActionMenu] = useState(null);
    const openActionMenu = useCallback((anchorEl, course) => setActionMenu({ anchorEl, course }), []);

    const pageCount = Math.ceil(allCourseCount / params.pageSize);

    useEffect(() => {


        const filters = [];

        if (params.courseStateFilter) {
            filters.push({ name: 'state', value: params.courseStateFilter });
        }
        if (params.courseIdFilter !== null) {
            filters.push({ name: 'id', value: params.courseIdFilter.courseId });
        } else {
            if (params.nameFilter !== '') {
                filters.push({ name: 'name', value: params.nameFilter });
            }
            if (params.participantFilter !== null) {
                filters.push({ name: 'participantId', value: params.participantFilter.id });
            }
            if (params.domainFilter !== 'any') {
                filters.push({ name: 'domain', value: params.domainFilter });
            }
            if (params.createdAfterFilter !== null) {
                filters.push({ name: 'createdAfter', value: params.createdAfterFilter });
            }
            if (params.createdBeforeFilter !== null) {
                filters.push({ name: 'createdBefore', value: params.createdBeforeFilter });
            }
            if (params.updatedAfterFilter !== null) {
                filters.push({ name: 'updatedAfter', value: params.updatedAfterFilter });
            }
            if (params.updatedBeforeFilter !== null) {
                filters.push({ name: 'updatedBefore', value: params.updatedBeforeFilter });
            }
        }

        const queryParams = {
            filters,
            fields: columns,
            orderBy: sorting.length > 0 ? sorting[0].id : undefined,
            orderByDesc: sorting.length > 0 ? sorting[0].desc : undefined,
            offset: (params.pageNumber - 1) * params.pageSize,
            limit: params.pageSize
        };

        setLoading(true);
        setCourses([]);
        setSelectedCourseIds([]);

        api.searchCourses(queryParams).then(x => {
            const courses = x.data.courses;
            const nameFilterTokens = (params.nameFilter || '').split(' ').filter(x => x !== '');
            for (var i = 0; i < courses.length; i++) {
                courses[i].nameMarked = markTokens(courses[i].name, nameFilterTokens);
                if (courses[i].created) {
                    courses[i].createdFormatted = format(new Date(courses[i].created), 'yyyy-MM-dd HH:mm')
                }
                if (courses[i].updated) {
                    courses[i].updatedFormatted = format(new Date(courses[i].updated), 'yyyy-MM-dd HH:mm')
                }
            }
            setCourses(courses);
            setAllCourseCount(x.data.allCount);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Classrooms.");
        })
    }, [params, refreshToggle, setAppError, columns, sorting]);

    const archiveView = params.courseStateFilter === 'ARCHIVED';

    return (
        <>
            <Portal container={titleRef.current}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="title" style={{ flex: 1 }}>
                        <span>Classrooms</span>
                        {archiveView &&
                            <span>&nbsp;(archived)</span>
                        }
                    </div>
                </div>
            </Portal>

            <CreateCourse open={createCourseOpen} onClose={() => setCreateCourseOpen(false)} onCreated={x => setCourseIdFilter({ courseId: x })} />
            <ChangeOwner courseId={changeOwnerCourseId} onClose={() => setChangeOwnerCourseId(null)} />
            <ArchiveCourse courses={archiveCourses} onClose={() => setArchiveCourses(null)} onArchived={() => setRefreshToggle(x => !x)} />
            <UpdateCourse courseId={updateCourseId} onClose={u => { setUpdateCourseId(null); setRefreshToggle(x => u !== x) }} />

            <AddTeachers courses={addTeachersCourses} onClose={() => setAddTeachersCourses(null)} />
            <AddStudents courses={addStudentsCourses} onClose={() => setAddStudentsCourses(null)} />
            <RemoveTeachers courses={removeTeachersCourses} onClose={() => setRemoveTeachersCourses(null)} />
            <RemoveStudents courses={removeStudentsCourses} onClose={() => setRemoveStudentsCourses(null)} />

            <CourseTeachers course={manageTeachersCourse} onClose={() => setManageTeachersCourse(null)} />
            <CourseStudents course={manageStudentsCourse} onClose={() => setManageStudentsCourse(null)} />

            <UnarchiveCourse courses={unarchiveCourses} onClose={() => setUnarchiveCourses(null)} onUnarchived={() => setRefreshToggle(x => !x)} />
            <DeleteCourse courses={deleteCourses} onClose={() => setDeleteCourses(null)} onDeleted={() => setRefreshToggle(x => !x)} />

            <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ width: '400px' }}>
                    <CourseFilters />
                    <div style={{ margin: '16px' }}>
                        <div style={{ marginBottom: '16px' }}>
                            <Typography>Actions</Typography>
                            <div className="underline" />
                        </div>
                        <div className="paper">
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}></div>
                                <div>
                                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setCreateCourseOpen(true)}>Create Classroom</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: '16px' }}>
                    <div style={{ marginBottom: '16px' }}>
                        <Typography className={classes.sectionLabel}>Classrooms</Typography>
                        <div className={classes.underline} />
                    </div>

                    <div className="paper" style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '0px', backgroundColor: '#f7f7f7' }}>
                        <div className={classes.buttonBar}>

                            <div style={{ marginLeft: '32px', flex: 1 }}>
                                {!archiveView &&
                                    <>
                                        <Tooltip title="Add Teachers to selected Classrooms" placement="bottom">
                                            <span>
                                                <IconButton disabled={selectedCourseIds.length === 0} onClick={() => setAddTeachersCourses(courses.filter(c => selectedCourseIds.some(x => x === c.id)))}>
                                                    <PersonIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Add Students to selected Classrooms" placement="bottom">
                                            <span>
                                                <IconButton disabled={selectedCourseIds.length === 0} onClick={() => setAddStudentsCourses(courses.filter(c => selectedCourseIds.some(x => x === c.id)))}>
                                                    <PersonIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Remove Teachers from selected Classrooms" placement="bottom">
                                            <span>
                                                <IconButton disabled={selectedCourseIds.length === 0} onClick={() => setRemoveTeachersCourses(courses.filter(c => selectedCourseIds.some(x => x === c.id)))}>
                                                    <PersonIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Remove Students from selected Classrooms" placement="bottom">
                                            <span>
                                                <IconButton disabled={selectedCourseIds.length === 0} onClick={() => setRemoveStudentsCourses(courses.filter(c => selectedCourseIds.some(x => x === c.id)))}>
                                                    <PersonIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Archive selected Classrooms" placement="bottom">
                                            <span>
                                                <IconButton disabled={selectedCourseIds.length === 0} onClick={() => setArchiveCourses(courses.filter(c => selectedCourseIds.some(x => x === c.id)))}>
                                                    <ArchiveIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </>
                                }
                                {archiveView &&
                                    <>
                                        <Tooltip title="Unarchive selected Classrooms" placement="bottom">
                                            <span>
                                                <IconButton disabled={selectedCourseIds.length === 0} onClick={() => setUnarchiveCourses(courses.filter(c => selectedCourseIds.some(x => x === c.id)))}>
                                                    <ArchiveIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Delete selected Classrooms" placement="bottom">
                                            <span>
                                                <IconButton disabled={selectedCourseIds.length === 0} onClick={() => setDeleteCourses(courses.filter(c => selectedCourseIds.some(x => x === c.id)))}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </>
                                }
                            </div>

                            <div >
                                <ColumnSelection columns={columns} onColumnsChange={setColumns} />
                            </div>
                        </div>

                        <CoursesTable
                            courses={courses}
                            loading={loading}
                            visibleColumns={columns}
                            sorting={sorting}
                            onSortingChange={setSorting}
                            openActionMenu={openActionMenu}
                            selectedCourseIds={selectedCourseIds}
                            onSelectedCourseIdsChange={setSelectedCourseIds} />

                        <PagingBar pageNumber={params.pageNumber} pageSize={params.pageSize} pageCount={pageCount} onPageNumberChange={setPageNumber} onPageSizeChange={setPageSize} />
                    </div>
                </div>
            </div>

            <Popover
                anchorEl={actionMenu?.anchorEl}
                keepMounted
                open={actionMenu !== null}
                onClose={() => setActionMenu(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            >
                {actionMenu?.course &&
                    <Paper>
                        {!archiveView &&
                            <List dense>
                                <ListItem button onClick={() => { setActionMenu(null); setUpdateCourseId(actionMenu.course.id); }}>
                                    <ListItemIcon><ListIcon /></ListItemIcon>
                                    <ListItemText primary="Classroom details" />
                                </ListItem>
                                <ListItem button onClick={() => { setActionMenu(null); setChangeOwnerCourseId(actionMenu.course.id); }}>
                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                    <ListItemText primary="Change Owner" />
                                </ListItem>
                                <ListItem button onClick={() => { setActionMenu(null); setManageTeachersCourse(actionMenu.course); }}>
                                    <ListItemIcon><PeopleAltIcon /></ListItemIcon>
                                    <ListItemText primary="Teachers" />
                                </ListItem>
                                <ListItem button onClick={() => { setActionMenu(null); setManageStudentsCourse(actionMenu.course); }}>
                                    <ListItemIcon><PeopleAltIcon /></ListItemIcon>
                                    <ListItemText primary="Students" />
                                </ListItem>
                                <ListItem button onClick={() => { setActionMenu(null); setArchiveCourses([actionMenu.course]); }}>
                                    <ListItemIcon><ArchiveIcon /></ListItemIcon>
                                    <ListItemText primary="Archive" />
                                </ListItem>
                                <a href={actionMenu.course.url} style={{ textDecoration: 'none', color: 'inherit' }} target="_blank" rel="noopener noreferrer">
                                    <ListItem button onClick={() => setActionMenu(null)}>
                                        <ListItemIcon><LaunchIcon /></ListItemIcon>
                                        <ListItemText primary="Open in Google Classroom" />
                                    </ListItem>
                                </a>
                            </List>
                        }
                        {archiveView &&
                            <>
                                <ListItem button onClick={() => { setActionMenu(null); setUnarchiveCourses([actionMenu.course]); }}>
                                    <ListItemIcon><ArchiveIcon /></ListItemIcon>
                                    <ListItemText primary="Unarchive" />
                                </ListItem>
                                <ListItem button onClick={() => { setActionMenu(null); setDeleteCourses([actionMenu.course]); }}>
                                    <ListItemIcon><DeleteForeverIcon /></ListItemIcon>
                                    <ListItemText primary="Delete" />
                                </ListItem>
                            </>
                        }
                    </Paper>
                }
            </Popover>
        </>
    );
}

export default Classrooms;
