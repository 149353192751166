import { useEffect, useState } from "react";
import { executeChanges } from "../../misc";
import api from "../../api";
import CourseParticipantsPanel from "./CourseParticipantsPanel";
import ConfirmUsersDialog from "../../components/ConfirmUsersDialog";
import ErrorListDialog from "../../components/ErrorListDialog";
import LoadingDialog from "../../components/LoadingDialog";
import SelectUsersDialog from "../../components/SelectUsersDialog";
import { useAppError } from "../../components/AppState";

function CourseStudents({ course, onClose }) {
    const { setAppError } = useAppError();

    const [students, setStudents] = useState([]);
    const [addStudentsOpen, setAddStudentsOpen] = useState(false);
    const [studentsToRemove, setStudentsToRemove] = useState(null);
    const [applyingChanges, setApplyingChanges] = useState(false);
    const [applyingChangesTitle, setApplyingChangesTitle] = useState('');
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [refreshToggle, setRefreshToggle] = useState(true);

    useEffect(() => {
        if (course === null) {
            return;
        }
        setStudents([]);
        setStudentsToRemove(null);
        setLoading(true);
        api.getCourseStudents(course.id).then(response => {
            setStudents(response.data);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Classsroom Students.");
        });

    }, [course, refreshToggle, setAppError]);


    const handleAddStudents = (users) => {
        setApplyingChanges(true);
        setApplyingChangesTitle('Adding students');

        const changes = users.map(x => ({
            getPromise: () => api.addStudent(course.id, x.id),
            failedText: `Adding student ${x.name} (${x.email}) failed.`
        }));

        executeChanges(changes, 3).onComplete.then(x => {
            setApplyingChanges(false);
            setRefreshToggle(x => !x)
            const failed = x.filter(x => x.result.failed === true).map(x => x.change.failedText);
            if (failed.length !== 0) {
                setErrors(failed);
            }
        });
    }

    const handleRemoveStudents = (users) => {
        setApplyingChanges(true);
        setApplyingChangesTitle('Removing students');

        const changes = users.map(x => ({
            getPromise: () => api.removeStudent(course.id, x.id),
            failedText: `Removing student ${x.name} (${x.email}) failed.`
        }));

        executeChanges(changes, 3).onComplete.then(x => {
            setApplyingChanges(false);
            setRefreshToggle(x => !x);
            const failed = x.filter(x => x.result.failed === true).map(x => x.change.failedText);
            if (failed.length !== 0) {
                setErrors(failed);
            }
        });
    }

    return (
        <>
            {course !== null &&
                <>
                    <SelectUsersDialog title="Add students" buttonText="Add" open={addStudentsOpen} onClose={() => setAddStudentsOpen(false)} onUsersSelected={handleAddStudents} />
                    <ConfirmUsersDialog title="Remove students" buttonText="Remove" users={studentsToRemove} onClose={() => setStudentsToRemove(null)} onConfirm={handleRemoveStudents} />
                    <LoadingDialog open={applyingChanges} title={applyingChangesTitle} />
                    <ErrorListDialog title="Completed with errors" errors={errors} onClose={() => setErrors(null)} />

                    <CourseParticipantsPanel
                        title={<div style={{ fontSize: '1.1rem' }}>Students of <span style={{ fontWeight: '500', color: '#555' }}>{course.name}</span></div>}
                        noUsersText="No students."
                        loading={loading}
                        users={students}
                        onAddOpen={() => setAddStudentsOpen(true)}
                        onRemove={users => setStudentsToRemove(users)}
                        onClose={onClose} />
                </>
            }
        </>
    )
}

export default CourseStudents;
