import {Portal } from "@material-ui/core";
import SideTabs from "../../components/SideTabs";
import Import from "./Import";
import Export from "./Export";



function ImportExport({ titleRef }) {

    return (
        <>
            <Portal container={titleRef.current}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="title" style={{ flex: 1 }}>
                        <span>Import Export</span>
                    </div>
                </div>
            </Portal>
            <SideTabs>
                <Import tabLabel="Import" tabId="import" />
                <Export tabLabel="Export" tabId="export" />
            </SideTabs>
        </>
    );
}

export default ImportExport;
