import { ClickAwayListener, LinearProgress, makeStyles, Popper, TextField, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import api from "../api";
import { markTokens } from "../misc"


const useStyles = makeStyles((theme) => ({
    noResults: {
        padding: theme.spacing(2)
    },
    selectList: {
        backgroundColor: '#fff',
        boxShadow: '0px 5px 6px 2px rgb(0 0 0 / 20%)',
        borderRadius: '4px',
        maxHeight: 300,
        width: 500,
        paddingTop: '8px',
        paddingBottom: '8px',
        overflow: 'auto',
    },
    selectItem: {
        padding: '8px 16px',
        borderBottom: '1px solid #eee',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#eee',
        }
    }
}))

function GroupSelect({ label, getMembers, onGroupSelected }) {
    const classes = useStyles();

    const groupSelectRef = useRef();
    const inputRef = useRef();
    const debounceTimerRef = useRef(null);
    const cancelSourceRef = useRef(null);

    const [inputValue, setInputValue] = useState('');
    const [groups, setGroups] = useState([]);
    const [listOpen, setListOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(debounceTimerRef.current);
        };
    }, []);

    const handleInputChange = (x) => {
        const value = x.target.value;
        setInputValue(value);
        setListOpen(false);

        clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = setTimeout(() => {
            if (cancelSourceRef.current !== null) {
                cancelSourceRef.current.cancel = true;
            }
            const cancelSource = { cancel: false };
            cancelSourceRef.current = cancelSource;

            setListOpen(false);
            setLoading(true);
            api.getGroups({ filter: value }).then(response => {
                if (cancelSource.cancel === true) {
                    return;
                }

                const tokens = value.split(' ').filter(x => x !== '');
                const groups = response.data.map(x => ({
                    group: x,
                    nameMarked: markTokens(x.name, tokens),
                    emailMarked: markTokens(x.email, tokens)
                }));
                setListOpen(true);
                setGroups(groups);
                setLoading(false);
            }, e => {
            });
        }, 300);
    }

    const handleFocus = (x) => {
        if (inputValue.length !== 0) {
            setListOpen(true);
        }
    }

    const handleOnClickAway = (x) => {
        if (x.target !== inputRef.current) {
            setListOpen(false);
        }
    }

    const handleKeyDown = (x) => {
        if (x.keyCode === 9) { // tab
            setListOpen(false);
        }
        if (x.keyCode === 27) { //escape
            setListOpen(false);
        }

        // if (listOpen) {
        //     if (x.keyCode === 38) { // up
        //         let index = groups.indexOf(focused);
        //         setFocused(groups[index - 1]);
        //         x.preventDefault();
        //     }

        //     if (x.keyCode === 40) { // down
        //         let index = groups.indexOf(focused);
        //         setFocused(groups[index + 1]);
        //         x.preventDefault();
        //     }

        //     if (x.keyCode === 13) { // enter
        //         handleSelect(focused);
        //     }
        // }
    }

    const handleSelect = (x) => {
        setListOpen(false);
        setInputValue("");
        if (getMembers) {
            setLoading(true);
            api.getGroupMembers(x.id).then(response => {
                x.users = response.data;
                onGroupSelected(x)
                setLoading(false);
            }, e => {
            });
        } else {
            onGroupSelected(x)
        }
    }

    return (
        <div ref={groupSelectRef}>
            <TextField inputRef={inputRef} autoComplete="off" autoFocus label={label} value={inputValue} onChange={handleInputChange} onFocus={handleFocus} onKeyDown={handleKeyDown} fullWidth />
            {loading &&
                <LinearProgress style={{ marginBottom: '-4px' }} />
            }
            <Popper open={listOpen} anchorEl={groupSelectRef.current} placement="bottom-start" style={{ zIndex: 1500 }}>
                <ClickAwayListener onClickAway={handleOnClickAway}>
                    <div className={classes.selectList}>
                        {groups.length !== 0 && groups.map((x, i) =>
                            <div key={x.id} onClick={() => handleSelect(x.group)} className={classes.selectItem} >
                                <div style={{ fontSize: '1.1rem', fontWeight: '500', color: '#555' }}>{x.nameMarked}</div>
                                <div>{x.emailMarked}</div>
                            </div>
                        )}
                        {groups.length === 0 &&
                            <div className={classes.noResults}>
                                <Typography variant="body2">No matching groups found.</Typography>
                            </div>
                        }
                    </div>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

export default GroupSelect;
