import { makeStyles, Portal } from '@material-ui/core';
import { useState } from 'react';
import Customers from './Customers';
import SystemUsers from './SystemUsers';

const useStyles = makeStyles((theme) => ({
    sectionButtonSelected: {
        padding: '16px',
        color: '#fff',
        backgroundColor: '#002c64',
        fontWeight: '500'
    },
    sectionButton: {
        cursor: 'pointer',
        padding: '16px',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
}));


function SectionButton({ selected, label, onClick }) {
    const classes = useStyles();

    return (
        <div>
            {selected === true &&
                <div className={classes.sectionButtonSelected}>
                    <span>{label}</span>
                </div>
            }
            {selected === false &&
                <div onClick={onClick} className={classes.sectionButton}>
                    <span>{label}</span>
                </div>
            }
        </div>
    )
}

function System({ titleRef }) {

    const [section, setSection] = useState('customers');

    return (
        <>
            <Portal container={titleRef.current}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="title" style={{ flex: 1 }}>
                        <span>Settings</span>
                    </div>
                </div>
            </Portal>
            <div style={{ flex: 1, display: 'flex', margin: '16px' }}>
                <div style={{ width: '300px', marginRight: '16px' }}>
                    <div className="paper">
                        <SectionButton selected={section === 'customers'} onClick={() => setSection('customers')} label="App Customers" />
                        <SectionButton selected={section === 'users'} onClick={() => setSection('users')} label="System Users" />
                    </div>
                </div>

                <div className="paper" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {section === 'customers' &&
                        <Customers />
                    }
                    {section === 'users' &&
                        <SystemUsers />
                    }
                </div>
            </div>
        </>
    )
}

export default System;
