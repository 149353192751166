import { Button, ButtonGroup, ClickAwayListener, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, makeStyles, MenuItem, Popper, Select, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import ClearIcon from '@material-ui/icons/Clear';
import UserSelect from "../../components/UserSelect";
import { format } from 'date-fns'
import api from "../../api";
import { useCourseListParams } from "../../components/AppState";

const useStyles = makeStyles((theme) => ({
    underline: {
        height: 3,
        width: 35,
        backgroundColor: '#00214C',
    },
    filter: {
        marginBottom: '8px'
    },
    paper: {
        maxHeight: 300,
        width: 500,
        overflow: 'auto',
    },
    filterNoHighlight: {
        whiteSpace: 'pre'
    },
    filterHighlight: {
        whiteSpace: 'pre',
        fontWeight: "bold"
    },
    userOu: {

    },
    userName: {

    },
    userEmail: {

    },
    noResults: {
        padding: theme.spacing(2)
    },
    input: {
        '&.MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
        },
        '& :disabled': {
            color: '#00214C',
        }
    }
}));


function ClearButton({ visible, onClick, ...props }) {
    const handleClick = (e) => {
        e.stopPropagation();
        onClick();
    }
    return (
        <>
            <InputAdornment {...props} position="end">
                {visible === true &&
                    <IconButton onClick={handleClick}>
                        <ClearIcon />
                    </IconButton>
                }
            </InputAdornment>
        </>
    )
}

function NameFilter() {

    const classes = useStyles();
    const { params, setNameFilter } = useCourseListParams();

    const debounceTimerRef = useRef(null);
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(params.nameFilter);
    }, [params]);

    const handleNameChange = (x) => {
        setValue(x);

        clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = setTimeout(() => setNameFilter(x), 300);
    }

    return (
        <div className={classes.filter}>
            <TextField fullWidth label="Name"
                value={value}
                onChange={x => handleNameChange(x.target.value)}
                InputProps={{ endAdornment: <ClearButton visible={value !== ''} onClick={() => setNameFilter('')} /> }}
            />
        </div>
    )
}

function ParticipantFilter() {

    const classes = useStyles();
    const { params, setParticipantFilter } = useCourseListParams();

    return (
        <div className={classes.filter}>
            <UserSelect user={params.participantFilter} label="Participant" onUserSelected={setParticipantFilter} />
        </div>
    )
}

function CourseIdFilter() {
    const classes = useStyles();
    const { params, setCourseIdFilter } = useCourseListParams();

    const anchorRef = useRef();
    const popupRef = useRef();

    const [open, setOpen] = useState(false);
    const [lookupValue, setLookupValue] = useState('');
    const [lookupLoading, setLookupLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (open) {
            setLookupValue('');
            setLookupLoading(false);
            setNotFound(false);
        }
    }, [open]);

    useEffect(() => {
        if (lookupLoading === false && popupRef.current) {
            popupRef.current.focus();
        }
    }, [lookupLoading]);

    const handleClickAway = () => {
        if (!lookupLoading) {
            setOpen(false);
        }
    }

    const handleKeyDown = (x) => {
        if (x.keyCode === 13) {
            if (notFound === true) {
                setOpen(false);
                return;
            }
            if (lookupLoading === false) {
                lookupCourseId(lookupValue);
            }
        }
        if (x.keyCode === 27) {
            setOpen(false);
        }
    }

    const lookupCourseId = (value) => {
        if (value === '') {
            setOpen(false);
            return;
        }
        setLookupLoading(true);
        api.lookupCourseId(value).then(x => {
            setCourseIdFilter({ courseId: x.data.courseId, archived: x.data.archived });
            setOpen(false);
        }, () => {
            setNotFound(true);
        }).then(() => setLookupLoading(false));
    }

    return (
        <>
            <div ref={anchorRef} className={classes.filter}>
                {params.courseIdFilter === null &&
                    <>
                        <InputLabel style={{ transform: 'translate(0, 24px) scale(1)' }}>Classroom Id</InputLabel>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' onClick={x => setOpen(true)} >From Id or URL</Button>
                        </div>
                    </>
                }
                {params.courseIdFilter !== null &&
                    <div>
                        <InputLabel shrink>Classroom Id</InputLabel>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, marginRight: '16px' }}>
                                <div style={{ display:'inline-block', padding: '4px 16px', borderRadius: '8px', color: '#fff', backgroundColor: '#557' }}>{params.courseIdFilter.courseId}</div>
                            </div>
                            <div>
                                <IconButton onClick={x => setCourseIdFilter(null)}>
                                    <ClearIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start" style={{ zIndex: 1500 }}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div ref={popupRef} onKeyDown={handleKeyDown} tabIndex="0" className="paper" style={{ border: '1px solid #ddd', outline: 'none' }}>
                        <div style={{ width: '500px' }}>
                            {lookupLoading === false && notFound === false &&
                                <div style={{ display: 'flex' }}>
                                    <TextField autoFocus fullWidth label="Classroom Id or Classroom URL" disabled={lookupLoading} value={lookupValue} onChange={x => setLookupValue(x.target.value)} />
                                    <div style={{ marginLeft: '16px' }}>
                                        <Button variant='contained' onClick={x => lookupCourseId(lookupValue)}>Ok</Button>
                                    </div>
                                </div>
                            }
                            {lookupLoading &&
                                <div>
                                    <div>
                                        <span>Looking up Classroom...</span>
                                    </div>
                                    <LinearProgress />
                                </div>
                            }
                            {notFound &&
                                <div>
                                    <span>No Classroom found!</span>
                                </div>
                            }
                        </div>
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

function DomainFilter() {
    const classes = useStyles();
    const [domains, setDomains] = useState([]);
    const { params, setDomainFilter } = useCourseListParams();

    useEffect(() => {
        api.getDomains().then(x => {
            setDomains(x.data);
        }, () => { })
    }, []);

    return (
        <>
            {(domains.length > 2) &&
                <div className={classes.filter}>
                    <FormControl fullWidth>
                        <InputLabel>Domain</InputLabel>
                        <Select value={params.domainFilter} onChange={x => setDomainFilter(x.target.value)}>
                            <MenuItem value={'any'}>Any</MenuItem>
                            {domains.map((x, i) =>
                                <MenuItem key={i} value={x}>{x}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            }
        </>
    )
}

function CourseFilters() {
    const classes = useStyles();

    const { params, setCourseStateFilter, setCreatedAfterFilter, setCreatedBeforeFilter, setUpdatedAfterFilter, setUpdatedBeforeFilter } = useCourseListParams();

    const handleCreatedAfterChange = (x) => setCreatedAfterFilter(x === null ? null : format(x, 'yyyy-MM-dd'));
    const handleCreatedBeforeChange = (x) => setCreatedBeforeFilter(x === null ? null : format(x, 'yyyy-MM-dd'));
    const handleUpdatedAfterChange = (x) => setUpdatedAfterFilter(x === null ? null : format(x, 'yyyy-MM-dd'));
    const handleUpdatedBeforeChange = (x) => setUpdatedBeforeFilter(x === null ? null : format(x, 'yyyy-MM-dd'));

    const activeButton = params.courseStateFilter === 'ACTIVE' ? { color: "primary", variant: "contained" } : {};
    const archivedButton = params.courseStateFilter === 'ARCHIVED' ? { color: "primary", variant: "contained" } : {};
    return (
        <div style={{ margin: '16px' }}>
            <div style={{ marginBottom: '16px' }}>
                <Typography className={classes.sectionLabel}>Filters</Typography>
                <div className={classes.underline} />
            </div>

            <div className="paper">
                {params.courseIdFilter === null &&
                    <>
                        <NameFilter />
                        <ParticipantFilter />
                        <DomainFilter />
                        <div className={classes.filter}>
                            <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Created after" variant="inline" value={params.createdAfterFilter} onChange={handleCreatedAfterChange}
                                InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={params.createdAfterFilter !== null} onClick={() => handleCreatedAfterChange(null)} /> }}
                            />
                        </div>
                        <div className={classes.filter}>
                            <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Created before" variant="inline" value={params.createdBeforeFilter} onChange={handleCreatedBeforeChange}
                                InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={params.createdBeforeFilter !== null} onClick={() => handleCreatedBeforeChange(null)} /> }}
                            />
                        </div>
                        <div className={classes.filter}>
                            <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Updated after" variant="inline" value={params.updatedAfterFilter} onChange={handleUpdatedAfterChange}
                                InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={params.updatedAfterFilter !== null} onClick={() => handleUpdatedAfterChange(null)} /> }}
                            />
                        </div>
                        <div className={classes.filter}>
                            <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Updated before" variant="inline" value={params.updatedBeforeFilter} onChange={handleUpdatedBeforeChange}
                                InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={params.updatedBeforeFilter !== null} onClick={() => handleUpdatedBeforeChange(null)} /> }}
                            />
                        </div>
                        <div className={classes.filter} style={{ display: 'flex', marginTop: '16px', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <InputLabel>Classroom state</InputLabel>
                            </div>
                            <ButtonGroup>
                                <Button onClick={() => setCourseStateFilter('ACTIVE')} {...activeButton} >Active</Button>
                                <Button onClick={() => setCourseStateFilter('ARCHIVED')} {...archivedButton}>Archived</Button>
                            </ButtonGroup>
                        </div>
                    </>
                }
                <CourseIdFilter />
            </div>
        </div>
    )
}

export default CourseFilters;
