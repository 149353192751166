import { ClickAwayListener, IconButton, InputAdornment, LinearProgress, makeStyles, Popper, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import api from "../api";
import { markTokens } from "../misc"
import DomainIcon from '@material-ui/icons/Domain';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles((theme) => ({
    noResults: {
        padding: theme.spacing(2)
    },
    selectList: {
        backgroundColor: '#fff',
        boxShadow: '0px 5px 6px 2px rgb(0 0 0 / 20%)',
        borderRadius: '4px',
        maxHeight: 300,
        width: 500,
        paddingTop: '8px',
        paddingBottom: '8px',
        overflow: 'auto',
    },
    selectItem: {
        padding: '8px 16px',
        borderBottom: '1px solid #eee',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#eee',
        }
    }
}))
function UserSelect({ user, label, onUserSelected }) {
    const classes = useStyles();

    const userSelectRef = useRef();
    const inputRef = useRef();
    const debounceTimerRef = useRef(null);
    const cancelSourceRef = useRef(null);

    const [inputValue, setInputValue] = useState('');
    const [users, setUsers] = useState([]);
    const [listOpen, setListOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user !== null) {
            setInputValue(user.name);
        } else {
            setInputValue('');
        }
    }, [user])

    useEffect(() => {
        return () => {
            clearTimeout(debounceTimerRef.current);
        }
    }, []);

    const handleInputChange = (x) => {
        const value = x.target.value;
        setInputValue(value);
        setListOpen(false);

        clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = setTimeout(() => {
            if (cancelSourceRef.current !== null) {
                cancelSourceRef.current.cancel = true;
            }
            const cancelSource = { cancel: false };
            cancelSourceRef.current = cancelSource;

            setListOpen(false);
            setLoading(true);
            api.getUsers({ filter: value }).then(response => {
                if (cancelSource.cancel === true) {
                    return;
                }

                const tokens = value.split(' ').filter(x => x !== '');
                const users = response.data.map(x => ({
                    user: x,
                    nameMarked: markTokens(x.name, tokens),
                    emailMarked: markTokens(x.email, tokens),
                    ouPathParts: x.orgUnitPath.split('/').filter(x => x !== '')
                }));
                setListOpen(true);
                setUsers(users);
                setLoading(false);
            }, e => {
            });
        }, 300);
    }

    const handleFocus = (x) => {
        if (inputValue.length !== 0 && user === null) {
            setListOpen(true);
        }
    }

    const handleOnClickAway = (x) => {
        if (x.target !== inputRef.current) {
            setListOpen(false);
        }
    }

    const handleKeyDown = (x) => {
        if (x.keyCode === 9) { //tab
            setListOpen(false);
        }
        if (x.keyCode === 27) { //escape
            setListOpen(false);
        }
    }

    const handleSelect = (x) => {
        if (user === null) {
            inputRef.current.focus();
        }
        setListOpen(false);
        setInputValue('');
        onUserSelected(x);
    }

    return (
        <div ref={userSelectRef}>
            <TextField
                inputRef={inputRef}
                autoComplete="off"
                autoFocus
                fullWidth
                label={label}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            {user !== null &&
                                <IconButton onClick={() => onUserSelected(null)}>
                                    <ClearIcon />
                                </IconButton>
                            }
                        </InputAdornment>
                }}
            />
            {loading &&
                <LinearProgress style={{ marginBottom: '-4px' }} />
            }
            <Popper open={listOpen} anchorEl={userSelectRef.current} placement="bottom-start" style={{ zIndex: 1500 }}>
                <ClickAwayListener onClickAway={handleOnClickAway}>
                    <div className={classes.selectList}>
                        {users.length !== 0 && users.map((x, i) =>
                            <div key={x.id} onClick={() => handleSelect(x.user)} className={classes.selectItem} >
                                <div style={{ fontSize: '1.1rem', fontWeight: '500', color: '#555' }}>{x.nameMarked}</div>
                                <div>{x.emailMarked}</div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#777' }}>
                                    <DomainIcon style={{ fontSize: '16px' }} />
                                    {
                                        x.ouPathParts.map((x, i) =>
                                            <React.Fragment key={i}>
                                                <ChevronRightIcon style={{ fontSize: '16px' }} />
                                                <span>{x}</span>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        )}
                        {users.length === 0 &&
                            <div className={classes.noResults}>
                                <Typography variant="body2">No matching users found.</Typography>
                            </div>
                        }
                    </div>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

export default UserSelect;
