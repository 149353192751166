import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import UserSelect from "../../components/UserSelect";
import api from "../../api";
import { NoContent, ScrollPanel, SidePanel } from "../../components/Misc";
import { useAppError } from "../../components/AppState";

const useStyles = makeStyles((theme) => ({
    user: {
        padding: '16px 16px 8px 16px',
        backgroundColor: '#eee',
        borderRadius: '4px'
    },
    userName: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: '#555'
    },
    userItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:not(:hover) .end-buttons': {
            display: 'none'
        },
        '&:hover .end-buttons': {
            display: 'block'
        }
    },
    permission: {
        display: 'flex',
        padding: '8px 16px 8px 24px',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    permissionItem: {
        backgroundColor: '#a6ccff',
        borderRadius: '4px',
        padding: '4px 8px',
        marginRight: '8px'
    },
    section: {
        display: 'flex',
        padding: '8px 16px 8px 24px',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        fontWeight: '500',
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
}));

const staticPermissionList = [
    {
        id: 'app-classrooms',
        name: 'Classrooms',
        description: 'Can access the Classrooms and Audit Records pages'
    }, {
        id: 'app-import-export',
        name: 'Import Export',
        description: 'Can access the Import Export page'
    }, {
        id: 'app-admin',
        name: 'Admin',
        description: 'Can access the Admin page'
    }
];


function UserPermissions({ user, permissionList, permissionListLoading, onClose }) {
    const { setAppError } = useAppError();
    const classes = useStyles();

    const [userPermissions, setUserPermissions] = useState([]);
    const [userPermissionsLoading, setUserPermissionsLoading] = useState(false);
    const loading = userPermissionsLoading || permissionListLoading;

    const [permissions, setPermissions] = useState([]);
    const [refreshToggle, setRefreshToggle] = useState(false);

    useEffect(() => {
        if (user !== null) {
            setUserPermissionsLoading(true);
            api.getAppUserPermissions(user.id).then(x => {
                setUserPermissions(x.data);
                setUserPermissionsLoading(false);
            }, () => {
                setUserPermissionsLoading(false);
                setAppError("Failed getting Permissions.");
            })
        }
    }, [user, refreshToggle, setAppError]);

    useEffect(() => {
        const p = permissionList.map(x => {
            const assigned = userPermissions.includes(x.id);
            return {
                permission: x,
                checked: assigned,
                assigned: assigned,
            }
        });
        setPermissions(p);
    }, [userPermissions, permissionList]);

    const handlePermissionCheckedChange = (permission, checked) => {
        permission.checked = checked;
        setPermissions(x => [...x]);
    }

    const handleUpdate = () => {
        setUserPermissionsLoading(true);
        const p = permissions.filter(x => x.checked).map(x => x.permission.id);
        api.updateAppUserPermissions(user.id, p).then(x => {
            setRefreshToggle(x => !x);
        }, () => {
            setUserPermissionsLoading(false);
            setAppError("Failed updating Permissions.");
        });
    }

    const hasChanges = permissions.some(x => x.checked !== x.assigned);

    return (
        <>
            {user &&
                <SidePanel title={<div style={{ fontSize: '1.2rem' }}>Permissions - {user.name}</div>} onClose={onClose} >
                    <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '8px 16px 8px 24px', minHeight: '64px' }}>
                        <div style={{ flex: 1, marginRight: '16px' }}></div>
                        <div>
                            <Button variant='contained' disabled={!hasChanges} color='primary' onClick={handleUpdate}>Save</Button>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading && <LinearProgress />}
                        {!loading &&
                            <ScrollPanel>
                                {permissions.map(x =>
                                    <div key={x.permission.id} className={classes.permission}>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ fontWeight: '500' }}>{x.permission.name}</div>
                                            <div style={{ fontStyle: 'italic' }}>{x.permission.description}</div>
                                        </div>
                                        <div>
                                            <Checkbox color="primary" checked={x.checked} onChange={c => handlePermissionCheckedChange(x, c.target.checked)} />
                                        </div>
                                    </div>
                                )}
                            </ScrollPanel>
                        }
                    </div>
                </SidePanel>
            }
        </>
    )
}


function UserSections({ user, sectionList, sectionListLoading, onClose }) {
    const { setAppError } = useAppError();
    const classes = useStyles();

    const [userSections, setUserSections] = useState([]);
    const [userSectionsLoading, setUserSectionsLoading] = useState(false);
    const loading = userSectionsLoading || sectionListLoading;

    const [sections, setSections] = useState([]);
    const [refreshToggle, setRefreshToggle] = useState(false);

    useEffect(() => {
        if (user !== null) {
            setUserSectionsLoading(true);
            api.getAppUserSections(user.id).then(x => {
                setUserSections(x.data);
                setUserSectionsLoading(false);
            }, () => {
                setUserSectionsLoading(false);
                setAppError("Failed getting User Sections.");
            })
        }
    }, [user, refreshToggle, setAppError]);

    useEffect(() => {
        const assignedSectionIds = userSections.map(x => x.id);
        const s = sectionList.map(x => {
            const assigned = assignedSectionIds.includes(x.id);
            return {
                section: x,
                checked: assigned,
                assigned: assigned,
            }
        });
        setSections(s);
    }, [userSections, sectionList]);

    const handleSectionCheckedChange = (section, checked) => {
        section.checked = checked;
        setSections(x => [...x]);
    }

    const handleUpdate = () => {
        setUserSectionsLoading(true);
        const s = sections.filter(x => x.checked).map(x => ({ id: x.section.id }));
        api.updateAppUserSections(user.id, s).then(x => {
            setRefreshToggle(x => !x);
        }, () => {
            setUserSectionsLoading(false);
            setAppError("Failed updating User Sections.");
        });
    }

    const hasChanges = sections.some(x => x.checked !== x.assigned);

    return (
        <>
            {user &&
                <SidePanel title={<div style={{ fontSize: '1.2rem' }}>Assigned sections - {user.name}</div>} onClose={onClose} >
                    <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '8px 16px 8px 24px' }}>
                        <div style={{ flex: 1, marginRight: '16px' }}>
                            <div>Restrict user to the content defined by the assigned sections.</div>
                            <div>Having no sections assigned will enable access to all content.</div>
                        </div>
                        <div>
                            <Button variant='contained' disabled={!hasChanges} color='primary' onClick={handleUpdate}>Save</Button>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading && <LinearProgress />}
                        {!loading && sections.length === 0 && <NoContent text="There are no sections defined." />}
                        {!loading &&
                            <ScrollPanel>
                                {sections.map(x =>
                                    <div key={x.section.id} className={classes.section}>
                                        <div style={{ flex: 1 }}>{x.section.name}</div>
                                        <Checkbox color="primary" checked={x.checked} onChange={c => handleSectionCheckedChange(x, c.target.checked)} />
                                    </div>
                                )}
                            </ScrollPanel>
                        }
                    </div>
                </SidePanel>
            }
        </>
    )
}


function AddAppUser({ open, onClose, onAdd }) {
    const classes = useStyles();

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (open) {
            setUser(null);
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">Add User</DialogTitle>
            <DialogContent>
                <div style={{ height: '80px' }}>
                    <div>
                        {user !== null &&
                            <div>
                                <div className={classes.user} style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        <div className={classes.userName}>{user.name}</div>
                                        <div>{user.email}</div>
                                    </div>
                                    <div>
                                        <IconButton onClick={() => setUser(null)}>
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        }
                        {user === null &&
                            <UserSelect user={null} label={'User'} onUserSelected={setUser} />
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">Cancel</Button>
                <Button onClick={() => { onClose(); onAdd(user); }} color="primary" disabled={user === null} >Add</Button>
            </DialogActions>
        </Dialog>
    )
}

function ConfirmRemoveAppUser({ user, onClose, onConfirm }) {
    return (
        <Dialog open={user !== null} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">Remove user?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="default">Cancel</Button>
                <Button onClick={() => onConfirm(user.id)} color="primary" >Remove</Button>
            </DialogActions>
        </Dialog>
    )
}

function AppUsers() {
    const { setAppError } = useAppError();
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [refreshToggle, setRefreshToggle] = useState(false);
    const [confirmRemoveUser, setConfirmRemoveUser] = useState(null);
    const [addUserOpen, setAddUserOpen] = useState(false);

    const [permissionList, setPermissionList] = useState([]);
    const [permissionListLoading, setPermissionListLoading] = useState(false);

    const [sectionList, setSectionList] = useState([]);
    const [sectionListLoading, setSectionListLoading] = useState(false);

    const [updatePermissionsUser, setUpdatePermissionsUser] = useState(null);
    const [updateSectionsUser, setUpdateSectionsUser] = useState(null);

    useEffect(() => {
        setPermissionListLoading(false);
        setPermissionList(staticPermissionList); // TODO get from serverside if needed.

        setSectionListLoading(true);
        api.getSections().then(x => {
            setSectionList(x.data);
            setSectionListLoading(false);
        }, () => {
            setSectionListLoading(false);
            setAppError("Failed getting Sections.");
        });
    }, [setAppError]);

    useEffect(() => {
        setLoading(true);
        api.getAppUsers().then(x => {
            setUsers(x.data);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Users.");
        });
    }, [refreshToggle, setAppError]);

    const handleAddUser = (x) => {
        api.addAppUser(x).then(response => {
            setUpdatePermissionsUser(response.data)
            setRefreshToggle(x => !x);
        }, () => {
            setAppError("Failed adding User.");
        });
    }

    const handleRemoveUser = (x) => {
        setLoading(true);
        setConfirmRemoveUser(null);
        api.removeAppUser(x).then(() => {
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Failed removing User.");
        });
    }

    return (
        <>
            <AddAppUser open={addUserOpen} onClose={() => setAddUserOpen(false)} onAdd={handleAddUser} />
            <ConfirmRemoveAppUser user={confirmRemoveUser} onClose={() => setConfirmRemoveUser(null)} onConfirm={handleRemoveUser} />

            <UserPermissions user={updatePermissionsUser} permissionList={permissionList} permissionListLoading={permissionListLoading} onClose={() => setUpdatePermissionsUser(null)} />
            <UserSections user={updateSectionsUser} sectionList={sectionList} sectionListLoading={sectionListLoading} onClose={() => setUpdateSectionsUser(null)} />

            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px', borderBottom: '1px solid #eee' }}>
                <div style={{ flex: '1', fontSize: '1.1rem', paddingLeft: '16px' }}>
                    Users
                </div>
                <div>
                    <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddUserOpen(true)}>Add User</Button>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                {loading && <LinearProgress />}
                {!loading && users.length === 0 && <NoContent text="No users." />}
                {!loading && users.length !== 0 &&
                    <ScrollPanel>
                        {users.map(x =>
                            <div key={x.id} className={classes.userItem}>
                                <div style={{ padding: '8px', flex: 1 }}>
                                    <div className={classes.userName}>{x.name}</div>
                                    <div>{x.email}</div>
                                </div>
                                <div className='end-buttons'>
                                    <Tooltip title="Permissions" placement="left-start">
                                        <IconButton onClick={() => setUpdatePermissionsUser(x)}>
                                            <LockOpenIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Assigned Sections" placement="left-start">
                                        <IconButton onClick={() => setUpdateSectionsUser(x)}>
                                            <ListIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Remove" placement="left-start">
                                        <IconButton onClick={() => setConfirmRemoveUser(x)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                    </ScrollPanel>
                }
            </div>
        </>
    )
}

export default AppUsers;
