
function markTokens(value, tokens) {
    const valueUp = value.toUpperCase();
    const tokensUp = tokens.map(x => x.toUpperCase());
    const markMap = new Array(value.length).fill(false);

    for (let i = 0; i < tokensUp.length; i++) {
        let start = 0;

        do {
            start = valueUp.indexOf(tokensUp[i], start);
            if (start !== -1) {
                const end = start + tokensUp[i].length;
                for (let j = start; j < end; j++) {
                    markMap[j] = true;
                }
                start = end;
            }
        } while (start !== -1)
    }

    let start = 0;
    let marked = false;
    let markedValue = [];
    for (let i = 0; i < markMap.length; i++) {
        if (markMap[i] !== marked) {
            marked = markMap[i];
            markedValue.push(value.slice(start, i));
            start = i;
        }
        if (i + 1 === markMap.length) {
            markedValue.push(value.slice(start, markMap.length));
        }
    }
    return (<>{markedValue.map((x, i) => <span key={i} className="mark">{x}</span>)}</>);
}

function executeChanges(changes, paralelismCount, onProgress) {
    const cancelToken = {
        canceled: false,
        cancel: () => { cancelToken.canceled = true }
    };

    const changesQueue = [...changes];
    const finised = [];

    const onItemComplete = (x) => {
        finised.push(x);
        onProgress && onProgress(x);
    }

    const executionPromises = [];
    for (let i = 0; i < paralelismCount; i++) {
        executionPromises.push(executeChangeChain(changesQueue, onItemComplete, cancelToken));
    }

    return {
        onComplete: Promise.all(executionPromises).then(_ => finised),
        cancel: cancelToken.cancel
    };
}

function executeChangeChain(changesQueue, onItemComplete, cancelToken) {
    const change = changesQueue.pop();
    if (change === undefined || cancelToken.canceled) {
        return Promise.resolve();
    }

    return change.getPromise().then(x => ({ change, result: { failed: false, value: x } }), e => ({ change, result: { failed: true, value: e } }))
        .then(x => {
            onItemComplete(x);
            return executeChangeChain(changesQueue, onItemComplete, cancelToken);
        });
}

export { markTokens, executeChanges };