import { Button, Dialog, DialogActions, DialogContent, InputLabel, DialogTitle, IconButton, InputAdornment, LinearProgress, makeStyles, TextField, Tooltip, FormControl, MenuItem, Select } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import api from '../../api';
import DescriptionIcon from '@material-ui/icons/Description';
import ListIcon from '@material-ui/icons/List';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { markTokens } from '../../misc';
import { FileUploader } from 'react-drag-drop-files';
import { NoContent, ScrollPanel, SidePanel } from '../../components/Misc';
import { useAppError, useImpersonateCustomer } from '../../components/AppState';

const useStyles = makeStyles((theme) => ({
    customerItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:not(:hover) .end-buttons': {
            display: 'none'
        },
        '&:hover .end-buttons': {
            display: 'block',
            paddingRight: '16px'
        }
    },
    connectionIdItem: {
        display: 'flex',
        padding: '8px 16px 8px 24px',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        fontWeight: '500',
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
}));

function CreateCustomer({ open, onClose, onCreated }) {
    const { setAppError } = useAppError();

    const [name, setName] = useState('');
    const [certificate, setCertificate] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [initialAppAdminEmail, setInitialAppAdminEmail] = useState('');
    const [apiScopes, setApiScopes] = useState('');
    const [createLoading, setCreateLoading] = useState(false);
    const [createError, setCreateError] = useState(null);

    const handleChange = file => {
        if (file.size > 4000 || file.type !== 'application/json') {
            return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setCertificate(reader.result);
        }, false);
        reader.readAsText(file);
    };

    useEffect(() => {
        if (open) {
            setName('');
            setCertificate('');
            setAdminEmail('');
            setInitialAppAdminEmail('');
            setCreateError(null);
            setCreateLoading(false);
        }
    }, [open]);

    useEffect(() => {
        api.getApiScopes().then(x => {
            const scopes = x.data.reduce((scopes, x) => scopes + "," + x);
            setApiScopes(scopes);
        }, () => {
            setAppError("Failed getting Api Scopes.");
        });
    }, [setAppError]);

    const handleCreate = () => {
        const customer = {
            name,
            certificate,
            adminEmail,
            initialAppAdminEmail
        };
        setCreateLoading(true);
        api.addCustomer(customer).then(x => {
            onClose();
            onCreated();
            setCreateLoading(false);
        }, x => {
            const error = (x.response && x.response.data.error) || "Unknown error."
            setCreateError(error);
            setCreateLoading(false);
        })
    }

    const createDisabled = name === '' || certificate === '' || adminEmail === '' || initialAppAdminEmail === '';

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth PaperProps={{ style: { height: '500px' } }}>
            <DialogTitle id="form-dialog-title">Add Customer</DialogTitle>
            {!createLoading && createError === null &&
                <>
                    <DialogContent style={{ height: '400px' }}>
                        <div style={{ marginBottom: '8px' }}>
                            Create an API project in <a href="https://console.cloud.google.com" target="_blank" rel="noopener noreferrer">Google Cloud Console</a>, enable&nbsp;
                            <a href="https://console.cloud.google.com/apis/library/admin.googleapis.com" target="_blank" rel="noopener noreferrer">Admin SDK API</a>&nbsp;and&nbsp;
                            <a href="https://console.cloud.google.com/apis/library/classroom.googleapis.com" target="_blank" rel="noopener noreferrer">Google Classroom API</a>&nbsp;for the  project. (For existing Edulife customers you can use the existing project).
                            <br></br>
                            Create a&nbsp;
                            <a href="https://console.cloud.google.com/iam-admin/serviceaccounts" target="_blank" rel="noopener noreferrer"> Service Account</a>&nbsp;for the project, create a Key(JSON) for the Service Account. Set created key in the Certificate fields below.
                        </div>
                        <div style={{ display: 'flex', marginBottom: '8px' }}>
                            <div style={{ flex: 1 }}>
                                In the &nbsp;<a href="https://admin.google.com/ac/owl/domainwidedelegation" target="_blank" rel="noopener noreferrer">Google Admin - Domain Wide Delegation</a>&nbsp;section, add the ClientId of the Service Account with the scopes:
                            </div>
                            <div>
                                <Button disabled={apiScopes === ''} variant="outlined" color="primary" onClick={() => navigator.clipboard.writeText(apiScopes)}>
                                    <FilterNoneIcon /><div style={{ marginLeft: '8px' }}>Copy Scopes</div>
                                </Button>
                            </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #ddd' }}></div>
                        <TextField label="Name" value={name} onChange={x => setName(x.target.value)} fullWidth required autoFocus />
                        <div style={{ display: 'flex', marginTop: '16px', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <InputLabel>Certificate *</InputLabel>
                            </div>
                            {certificate !== '' &&
                                <>
                                    <div style={{ color: '#fff', backgroundColor: '#3f51b5', borderRadius: '4px', padding: '8px', display: 'flex', alignItems: 'center' }}>
                                        <DescriptionIcon />
                                        <div style={{ marginLeft: '16px' }}>Certificate</div>
                                    </div>
                                    <div>
                                        <IconButton onClick={() => setCertificate('')}>
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                </>
                            }
                            {certificate === '' &&
                                <div>
                                    <FileUploader
                                        handleChange={handleChange}
                                        name="file"
                                        types={["JSON"]}
                                    >
                                        <div style={{ border: '2px dashed #3f51b5', borderRadius: '4px', padding: '12px' }}>Click to browse or Drag and Drop here</div>
                                    </FileUploader>
                                </div>
                            }
                        </div>
                        <div></div>
                        <TextField label="Admin email (used for Google API calls)" value={adminEmail} onChange={x => setAdminEmail(x.target.value)} fullWidth required />
                        <TextField label="Initial user email" value={initialAppAdminEmail} onChange={x => setInitialAppAdminEmail(x.target.value)} fullWidth required />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="default">Cancel</Button>
                        <Button onClick={handleCreate} color="primary" disabled={createDisabled} >Create</Button>
                    </DialogActions>
                </>
            }
            {createLoading &&
                <>
                    <DialogContent>
                        <LinearProgress />
                    </DialogContent>
                </>
            }
            {createError !== null &&
                <>
                    <DialogContent>
                        Error adding customer: {createError}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'flex-start' }}>
                        <Button startIcon={<ChevronLeftIcon />} onClick={() => setCreateError(null)}>Back</Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}


function UpdateCustomer({ customer, onClose, onUpdated }) {
    const [name, setName] = useState('');
    const [status, setStatus] = useState('');
    const [certificate, setCertificate] = useState('no-change');
    const [adminEmail, setAdminEmail] = useState('');
    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const handleChange = file => {
        if (file.size > 4000 || file.type !== 'application/json') {
            return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setCertificate(reader.result);
        }, false);
        reader.readAsText(file);
    };

    useEffect(() => {
        if (customer !== null) {
            setName(customer.name);
            setStatus(customer.status);
            setCertificate('no-change');
            setAdminEmail(customer.adminEmail);
        }
    }, [customer]);

    const handleUpdate = () => {
        const updatedCustomer = {
            id: customer.id,
            name,
            status,
            certificate: certificate === 'no-change' ? null : certificate,
            adminEmail
        };
        setUpdateLoading(true);
        api.updateCustomer(updatedCustomer).then(x => {
            onClose();
            onUpdated();
            setUpdateLoading(false);
        }, x => {
            const error = (x.response && x.response.data.error) || "Unknown error."
            setUpdateError(error);
            setUpdateLoading(false);
        });
    }

    const updateDisable = name === '' || certificate === '' || adminEmail === '';

    return (
        <Dialog open={customer !== null} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth PaperProps={{ style: { height: '350px' } }}>
            <DialogTitle id="form-dialog-title">Update Customer</DialogTitle>
            {!updateLoading && updateError === null &&
                <>
                    <DialogContent>
                        <div style={{ display: 'flex', marginTop: '16px', alignItems: 'center' }}>
                            <div style={{ marginRight: '24px' }}>
                                <InputLabel>Status</InputLabel>
                            </div>
                            <div style={{ flex: 1 }}>
                                <FormControl fullWidth>
                                    <Select value={status} onChange={x => setStatus(x.target.value)}>
                                        <MenuItem value={'Active'}>Active</MenuItem>
                                        <MenuItem value={'Suspended'}>Suspended</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <TextField label="Name" value={name} onChange={x => setName(x.target.value)} fullWidth required autoFocus />
                        <div style={{ display: 'flex', marginTop: '16px', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <InputLabel>Certificate *</InputLabel>
                            </div>
                            {certificate !== '' &&
                                <>
                                    <div style={{ color: '#fff', backgroundColor: '#3f51b5', borderRadius: '4px', padding: '8px', display: 'flex', alignItems: 'center' }}>
                                        <DescriptionIcon />
                                        <div style={{ marginLeft: '16px' }}>Certificate</div>
                                    </div>
                                    <div>
                                        <IconButton onClick={() => setCertificate('')}>
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                </>
                            }
                            {certificate === '' &&
                                <div>
                                    <FileUploader
                                        handleChange={handleChange}
                                        name="file"
                                        types={["JSON"]}
                                    >
                                        <div style={{ border: '2px dashed #3f51b5', borderRadius: '4px', padding: '12px' }}>Click to browse or Drag and Drop here</div>
                                    </FileUploader>
                                </div>
                            }
                        </div>
                        <div></div>
                        <TextField label="Admin email (used for Google API calls)" value={adminEmail} onChange={x => setAdminEmail(x.target.value)} fullWidth required />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="default">Cancel</Button>
                        <Button onClick={handleUpdate} color="primary" disabled={updateDisable} >Update</Button>
                    </DialogActions>
                </>
            }
            {updateLoading &&
                <>
                    <DialogContent>
                        <LinearProgress />
                    </DialogContent>
                </>
            }
            {updateError !== null &&
                <>
                    <DialogContent>
                        Error updating customer: {updateError}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'flex-start' }}>
                        <Button startIcon={<ChevronLeftIcon />} onClick={() => setUpdateError(null)}>Back</Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}

function CustomerEdulifeConnectionIds({ customer, onClose }) {
    const { setAppError } = useAppError();
    const [connectionIdValue, setConnectionIdValue] = useState('');
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [connectionIds, setConnectionIds] = useState([]);
    const [existingConnectionIds, setExistingConnectionIds] = useState(null);
    const [refreshToggle, setRefreshToggle] = useState(false);

    useEffect(() => {
        if (customer !== null) {
            setConnectionIdValue('');
            setConnectionIds([]);
            setLoading(true);
            api.getCustomerEdulifeConnectionIds(customer.id).then(x => {
                setExistingConnectionIds(x.data);
                setConnectionIds(x.data);
                setLoading(false);
            }, () => {
                setLoading(false);
                setAppError("Failed getting Section Setup.");
            })
        }
    }, [customer, refreshToggle, setAppError]);

    const handleUpdate = () => {
        setLoading(true);
        api.updateCustomerEdulifeConnectionIds(customer.id, connectionIds).then(x => {
            setRefreshToggle(x => !x);
        }, () => {
            setLoading(false);
            setAppError("Failed updating Section Setup.");
        });
    }

    const hasChanges = existingConnectionIds !== null && (connectionIds.length !== existingConnectionIds.length || connectionIds.some(x => !existingConnectionIds.includes(x)));

    return (
        <>
            {customer &&
                <SidePanel title={<div style={{ fontSize: '1.2rem' }}>Section Setup - {customer.name}</div>} onClose={onClose} >
                    <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '8px 16px 8px 24px', alignItems: 'end' }}>
                        <div>
                            <TextField margin="dense" label="ConnectionId" value={connectionIdValue} onChange={x => setConnectionIdValue(x.target.value)} />
                        </div>
                        <div style={{ flex: 1, marginLeft: '16px' }}>
                            <Button color="primary" variant='contained' startIcon={<AddIcon />} onClick={() => { setConnectionIds(x => ([...x, connectionIdValue])); setConnectionIdValue(''); }}>Add</Button>
                        </div>
                        <div>
                            <Button variant='contained' disabled={!hasChanges} color='primary' onClick={handleUpdate}>Save</Button>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading && <LinearProgress />}
                        {!loading && connectionIds.length === 0 && <NoContent text="No Connection Ids added." />}
                        {!loading &&
                            <ScrollPanel>
                                {connectionIds.map(x =>
                                    <div key={x} className={classes.connectionIdItem}>
                                        <div style={{ flex: 1 }}>{x}</div>
                                        <div>
                                            <IconButton onClick={() => setConnectionIds(c => c.filter(c => c !== x))}>
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </ScrollPanel>
                        }
                    </div>
                </SidePanel>
            }
        </>
    )
}

function Customers() {

    const classes = useStyles();
    const { setAppError } = useAppError();
    const { setImpersonateCustomer } = useImpersonateCustomer();

    const [nameValue, setNameValue] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createCustomerOpen, setCreateCustomerOpen] = useState(false);
    const [updateCustomer, setUpdateCustomer] = useState(null);
    const [editCustomerEdulifeConnectionIds, setEditCustomerEdulifeConnectionIds] = useState(null);
    const [refreshToggle, setRefreshToggle] = useState([]);

    const debounceTimerRef = useRef(null);

    const handleNameValueChange = (x) => {
        setNameValue(x);
        clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = setTimeout(() => setNameFilter(x), 300);
    }

    useEffect(() => {
        setLoading(true);
        const limit = 20;
        const filters = {
            name: nameFilter,
            offset: (pageNumber - 1) * limit,
            limit: limit
        };

        api.getCustomers(filters).then(response => {
            const custs = response.data;
            const nameFilterTokens = nameFilter.split(' ').filter(x => x !== '');
            for (var i = 0; i < custs.length; i++) {
                custs[i].nameMarked = markTokens(custs[i].name, nameFilterTokens);
            }
            setCustomers(custs);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Customers.");
        });

    }, [nameFilter, pageNumber, refreshToggle, setAppError]);

    const handleImpersonateCustomer = (customer) => {
        const impersonateCustomer = {
            id: customer.id,
            name: customer.name
        };
        setImpersonateCustomer(impersonateCustomer);
    }

    return (
        <>
            <CreateCustomer open={createCustomerOpen} onClose={() => setCreateCustomerOpen(false)} onCreated={() => setRefreshToggle((x => !x))} />
            <UpdateCustomer customer={updateCustomer} onClose={() => setUpdateCustomer(null)} onUpdated={() => setRefreshToggle((x => !x))} />
            <CustomerEdulifeConnectionIds customer={editCustomerEdulifeConnectionIds} onClose={() => setEditCustomerEdulifeConnectionIds(null)} />

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', margin: '16px' }}>
                    <div style={{ width: '300px' }}>
                        <TextField
                            label="name"
                            fullWidth
                            value={nameValue}
                            onChange={x => handleNameValueChange(x.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {nameValue !== '' &&
                                            <IconButton onClick={() => handleNameValueChange('')}>
                                                <ClearIcon />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                            }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                    <div>
                        <Button color="primary" variant='contained' startIcon={<AddIcon />} onClick={() => setCreateCustomerOpen(true)}>Add Customer</Button>
                    </div>

                </div>
                <div style={{ flex: 1 }}>
                    {loading && <LinearProgress />}
                    {!loading &&
                        <ScrollPanel>
                            {customers.map(x =>
                                <div key={x.id} className={classes.customerItem}>
                                    <div style={{ padding: '16px', flex: 1 }}>
                                        {x.nameMarked}
                                    </div>
                                    <div style={{ width: '80px' }}>
                                        {x.status}
                                    </div>
                                    <div style={{ width: '160px' }}>
                                        <div className='end-buttons'>
                                            <Tooltip title="Open CM as customer" placement="left-start">
                                                <IconButton onClick={() => { handleImpersonateCustomer(x) }}>
                                                    <OpenInNewIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit" placement="left-start">
                                                <IconButton onClick={() => { setUpdateCustomer(x) }}>
                                                    <ListIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sections setup" placement="left-start">
                                                <IconButton onClick={() => { setEditCustomerEdulifeConnectionIds(x) }}>
                                                    <FilterNoneIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ScrollPanel>
                    }
                </div>
                <div>
                    <div style={{ borderTop: '1px solid #ccc', paddingTop: '8px', display: 'flex', height: '50px', alignItems: 'center' }} >
                        <div style={{ flex: 1 }}></div>
                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(1)}><FirstPageIcon /></IconButton>
                        <div>Page {pageNumber}</div>
                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><NavigateBeforeIcon /></IconButton>
                        <IconButton onClick={() => setPageNumber(pageNumber + 1)}><NavigateNextIcon /></IconButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Customers;
