import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton, LinearProgress, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../api";
import UserSelect from "../../components/UserSelect";
import ClearIcon from '@material-ui/icons/Clear';
import LoadingDialog from "../../components/LoadingDialog";
import ErrorListDialog from "../../components/ErrorListDialog";
import { useAppError } from "../../components/AppState";


const useStyles = makeStyles((theme) => ({
    user: {
        padding: '16px 16px 8px 16px',
        backgroundColor: '#eee',
        borderRadius: '4px'
    },
    userName: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: '#555'
    }

}));


function ChangeOwner({ courseId, onClose }) {
    const classes = useStyles();
    const { setAppError } = useAppError();

    const [owner, setOwner] = useState(null);
    const [ownerLoading, setOwnerLoading] = useState(false);
    const [newOwner, setNewOwner] = useState(null);

    const [applyingChanges, setApplyingChanges] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (courseId !== null) {
            setOwnerLoading(true);
            setOwner(null);
            setNewOwner(null);
            api.getCourseOwner(courseId).then(response => {
                setOwner(response.data);
                setOwnerLoading(false);
            }, () => {
                setOwnerLoading(false);
                setAppError("Error getting Classroom owner");
            });
        }
    }, [courseId, setAppError]);

    const handleChangeClick = () => {
        onClose();
        setApplyingChanges(true);
        api.updateCourseOwner(courseId, newOwner.id).then(null, () => {
            setErrors(["Changing classroom owner failed"]);
        }).then(() => {
            setApplyingChanges(false);
        });
    }

    return (
        <>
            <Dialog open={courseId !== null} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Change Classroom Owner</DialogTitle>
                <DialogContent>
                    <div style={{ height: '200px' }}>
                        <div>{ownerLoading && <LinearProgress />}</div>
                        <div style={{ marginBottom: '16px' }}>
                            {owner !== null &&
                                <div>
                                    <FormLabel>Current owner</FormLabel>
                                    <div className={classes.user}>
                                        <div className={classes.userName}>{owner.name}</div>
                                        <div>{owner.email}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            {newOwner !== null &&
                                <div>
                                    <FormLabel>New owner</FormLabel>
                                    <div className={classes.user} style={{ display: 'flex' }}>
                                        <div style={{ flex: 1 }}>
                                            <div className={classes.userName}>{newOwner.name}</div>
                                            <div>{newOwner.email}</div>
                                        </div>
                                        <div>
                                            <IconButton onClick={() => setNewOwner(null)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            }
                            {newOwner === null &&
                                <UserSelect user={null} label={'New Owner'} onUserSelected={x => setNewOwner(x)} />
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="default">Cancel</Button>
                    <Button onClick={handleChangeClick} color="primary" disabled={newOwner === null || newOwner.id === owner.id} >Change</Button>
                </DialogActions>
            </Dialog>

            <LoadingDialog open={applyingChanges} title="Changing classroom owner" />
            <ErrorListDialog title="Completed with errors" errors={errors} onClose={() => setErrors(null)} />
        </>
    )
}

export default ChangeOwner;