import { Button, IconButton, LinearProgress, List, ListItem, makeStyles, Paper, Popover, Portal } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import api from '../api';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { format } from 'date-fns';
import { useAppError } from '../components/AppState';


const useStyles = makeStyles((theme) => ({
    desc: {
        display: 'flex',
        alignItems: 'center'
    },
    user: {
        margin: '8px 8px 4px 8px',
    },
    userName: {
        fontWeight: '500',
    },
    userEmail: {
        fontSize: '0.8rem',
        color: '#888',
    },
    course: {
        margin: '8px',
        fontWeight: '500',
    },
    tableHeader: {
        '& th': {
            fontWeight: 'normal',
            background: '#fff',
            position: 'sticky',
            top: 0,
            padding: '8px 0px',
            textAlign: 'left'
        },
        '& th::after': {
            content: '',
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            boxShadow: '0px 2px 4px 0 rgb(0 0 0 / 5%)'
        }
    },
    recordRow: {
        paddingTop: '8px',
        paddingBottom: '16px',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        },
    }

}));


function Course({ course }) {
    const classes = useStyles();
    return (
        <div className={classes.course} title={course.id}>
            {course.name}
        </div>
    )
}

function User({ user }) {
    const classes = useStyles();
    return (
        <div className={classes.user}>
            <div className={classes.userName}>{user.name}</div>
            <div className={classes.userEmail}>{user.email}</div>
        </div>
    )
}

function Record({ item }) {
    const classes = useStyles();

    let action = (<></>);
    let description = (<></>);

    switch (item.recordType) {
        case 'createCourse':
            action = <span>Classroom created</span>
            description = <div className={classes.desc}><div>Classroom</div><Course course={item.course} /><div>created</div></div>
            break;
        case 'updateCourse':
            action = <span>Classroom Updated</span>
            description = <div className={classes.desc}><div>Details of Classroom</div><Course course={item.course} /><div>updated</div></div>
            break;
        case 'changeOwner':
            action = <span>Classroom Owner Changed</span>
            description = <div className={classes.desc}><div>Owner of Classroom</div><Course course={item.course} /><div>changed to</div><User user={item.user} /></div>
            break;
        case 'addTeacher':
            action = <span>Teacher Added</span>
            description = <div className={classes.desc}><div>Teacher</div><User user={item.user} /><div>added to classroom</div><Course course={item.course} /></div>
            break;
        case 'removeTeacher':
            action = <span>Teacher Removed</span>
            description = <div className={classes.desc}><div>Teacher</div><User user={item.user} /><div>removed from classroom</div><Course course={item.course} /></div>
            break;
        case 'addStudent':
            action = <span>Student Added</span>
            description = <div className={classes.desc}><div>Student</div><User user={item.user} /><div>added to classroom</div> <Course course={item.course} /></div>
            break;
        case 'removeStudent':
            action = <span>Student Removed</span>
            description = <div className={classes.desc}><div>Student</div><User user={item.user} /> removed from classroom <Course course={item.course} /></div>
            break;
        case 'archiveCourse':
            action = <span>Classroom archive</span>
            description = <div className={classes.desc}><div>Classroom</div><Course course={item.course} /><div>archived</div></div>
            break;
        case 'unarchiveCourse':
            action = <span>Classroom unarchived</span>
            description = <div className={classes.desc}><div>Classroom</div><Course course={item.course} /><div>unarchived</div></div>
            break;
        case 'deleteCourse':
            action = <span>Classroom deleted</span>
            description = <div className={classes.desc}><div>Classroom</div><Course course={item.course} /><div>deleted</div></div>
            break;
        default:
            break;
    }

    return (
        <tr className={classes.recordRow}>
            <td>{action}</td>
            <td>{description}</td>
            <td><User user={item.actingUser} /></td>
            <td>{format(new Date(item.date), 'yyyy-MM-dd HH:mm:ss')}</td>
        </tr>
    );
}




function AuditRecords({ titleRef }) {
    const { setAppError } = useAppError();
    const classes = useStyles();

    const pageSizeMenuAnchorRef = useRef();
    const [pageSizeMenuOpen, setPageSizeMenuOpen] = useState(false);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);

    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        let filters = {
            offset: (pageNumber - 1) * pageSize,
            limit: pageSize
        };

        api.getAuditRecords(filters).then(response => {

            setRecords(response.data);
            setLoading(false);
        }, () => {
            setLoading(false);
            setAppError("Failed getting Permissions.");
        });
    }, [pageNumber, pageSize, setAppError]);

    return (
        <>
            <Portal container={titleRef.current}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="title" style={{ flex: 1 }}>
                        <span>Audit Records</span>
                    </div>
                </div>
            </Portal>
            <div className="paper" style={{ margin: '16px', display: 'flex', flexDirection: 'column', flex: 1 }}>
                {loading &&
                    <div style={{ flex: 1, marginTop: '8px' }} >
                        <LinearProgress />
                    </div>
                }
                {!loading &&
                    <div style={{ position: 'relative', flex: 1, overflowX: 'hidden', overflowY: 'scroll', backgroundColor: '#fff' }}>
                        <div style={{ position: 'absolute' }}>
                            <table style={{ tableLayout: 'fixed', borderSpacing: '0', borderCollapse: 'collapse', width: '100%' }} >
                                <tbody>
                                    <tr className={classes.tableHeader}>
                                        <th style={{ width: '250px' }}><div>Action</div></th>
                                        <th style={{ width: '100%' }}><div>Description</div></th>
                                        <th style={{ width: '350px' }}><div style={{ marginLeft: '8px' }}>Done by</div></th>
                                        <th style={{ width: '200px' }}><div>Date</div></th>
                                    </tr>
                                    {records.map(x =>
                                        <Record key={x.id} item={x} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                <div style={{ borderTop: '1px solid #ccc', paddingTop: '8px', display: 'flex', height: '50px', alignItems: 'center' }} >
                    <div>Items per page:</div>
                    <div ref={pageSizeMenuAnchorRef}>
                        <Button onClick={() => setPageSizeMenuOpen(true)}><span style={{ fontWeight: 400 }}>{pageSize}</span><ArrowDropDownIcon /></Button>
                    </div>
                    <Popover
                        id="page-size-menu"
                        open={pageSizeMenuOpen}
                        anchorEl={pageSizeMenuAnchorRef.current}
                        onClose={() => { setPageSizeMenuOpen(false) }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                    >
                        <Paper >
                            <List>
                                {[10, 20, 50, 100].map((x, index) =>
                                    <ListItem key={index} button onClick={() => { setPageSizeMenuOpen(false); setPageSize(x); }}>{x}</ListItem>
                                )}
                            </List>
                        </Paper>
                    </Popover>
                    <div style={{ flex: 1 }}></div>

                    <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(1)}><FirstPageIcon /></IconButton>
                    <div>Page {pageNumber}</div>
                    <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><NavigateBeforeIcon /></IconButton>
                    <IconButton onClick={() => setPageNumber(pageNumber + 1)}><NavigateNextIcon /></IconButton>
                    {/* disabled={pageNumber + 1 > pageCount} */}
                </div>
            </div>
        </>
    )
}

export default AuditRecords;
