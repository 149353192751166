import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../api";
import { useAppError } from "../../components/AppState";
import LoadingDialog from "../../components/LoadingDialog";

function CreateCourse({ open, onClose, onCreated }) {
    const { setAppError } = useAppError();

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setName('');
            setLoading(false);
        }
    }, [open]);

    const handleCreate = () => {
        onClose();
        setLoading(true);
        api.createCourse({ name }).then(response => {
            setLoading(false);
            onCreated(response.data.courseId);
        }, () => {
            setLoading(false);
            setAppError("Failed creating Classroom.");
        });
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Create Classroom</DialogTitle>
                <DialogContent>
                    <TextField margin="dense" label="Name" value={name} onChange={x => setName(x.target.value)} fullWidth required autoFocus />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="default">Cancel</Button>
                    <Button onClick={handleCreate} color="primary" disabled={name.length === 0} >Create</Button>
                </DialogActions>
            </Dialog>
            <LoadingDialog open={loading} title="Creating classroom" />
        </>
    )
}

export default CreateCourse;
