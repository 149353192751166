import { useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Classrooms from './pages/Classrooms';
import AuditRecords from './pages/AuditRecords';
import Admin from './pages/Admin';
import System from './pages/System';
import Account from './Account';
import AppError from './components/AppError';
import { LogoIcon, AuditRecordIcon, AuditRecordIconDark, SettingsIcon, ClassroomsIcon, ExportIcon } from './icons';
import './App.css';
import { useAccount } from './components/AppState';
import ImportExport from './pages/ImportExport';

const useStyles = makeStyles((theme) => ({
    topbar: {
        display: 'flex',
        height: '80px',
        alignItems: 'center',
        backgroundColor: '#FFF',
        boxShadow: '0px 4px 8px 0px #00000033',
        zIndex: 1000
    },
    corner: {
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#00214C',
    },
    header: {
        backgroundColor: '#FFF',
    },
    sidebar: {
        width: '130px',
        backgroundColor: '#002C64',
    },
    sidebarButton: {
        color: '#FFF',
        '& .MuiSvgIcon-root': {
            fontSize: 32
        },
        '& .sidebutton-content': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            marginTop: '32px',
            marginBottom: '32px'
        },
        '& .selected': {
            flex: 1,
            backgroundColor: '#176DDE',
            borderRadius: '2px',
            marginLeft: '16px',
            marginRight: '16px'
        }
    },
    content: {
        overflowY: 'auto',
    },
    homeCard: {
        backgroundColor: '#fff',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        width: '400px',
        marginRight: '48px',
        marginBottom: '48px',
        padding: '36px 32px',
        cursor: 'pointer',
        '& .MuiSvgIcon-root': {
            fontSize: 48
        },
        '&:hover': {
            backgroundColor: '#eee'
        },
    }
}))

function HomeCard({ history, path, title, icon }) {
    const classes = useStyles();
    return (
        <div className={classes.homeCard} onClick={() => history.push(path)}>
            <div style={{ marginRight: '32px' }}>
                {icon}
            </div>

            <div style={{ fontSize: '1.2rem', fontWeight: '500' }}>
                {title}
            </div>
        </div>
    )
}

function SideButton({ history, text, path, children }) {
    const classes = useStyles();
    return (
        <div>
            <Tooltip title={text} placement="right">
                <Button onClick={() => history.push(path)} className={classes.sidebarButton} fullWidth>
                    <div className="sidebutton-content">
                        <div>
                            {children}
                        </div>
                        <div style={{ height: '4px', display: 'flex' }}>
                            <Route path={path}>
                                <div className="selected"></div>
                            </Route>
                        </div>
                    </div>
                </Button>
            </Tooltip>
        </div>
    )
}

function App() {
    const classes = useStyles();
    const history = useHistory()
    const titleRef = useRef();
    const { account } = useAccount();

    const [pages, setPages] = useState({
        classroom: false,
        importExport: false,
        audit: false,
        admin: false,
        system: false,
        noAccess: false,
    })

    useEffect(() => {
        if (account !== null) {
            const pages = {
                classroom: account.permissions.includes('app-classrooms'),
                importExport: account.permissions.includes('app-import-export'),
                audit: account.permissions.includes('app-classrooms'),
                admin: account.permissions.includes('app-admin'),
                system: account.permissions.includes('system-admin'),
                noAccess: account.permissions.includes('no-app-access'),
            };
            setPages(pages);
        } else {
            history.push('/');
            setPages({});
        }
    }, [account, history]);

    const pageProps = { titleRef }

    return (
        <>
            <AppError />
            <div style={{ display: 'flex', minHeight: '100vh' }}>
                <div className={classes.sidebar}>
                    <div className={classes.corner}>
                        <Tooltip title="Home" placement="right">
                            <Button onClick={() => history.push('/')} className={classes.sidebarButton}>
                                <LogoIcon fontSize="large" />
                            </Button>
                        </Tooltip>
                    </div>
                    {pages.classroom &&
                        <SideButton history={history} path='/classrooms' text='Classrooms'><ClassroomsIcon /></SideButton>
                    }
                    {pages.importExport &&
                        <SideButton history={history} path='/import-export' text='Import Export'><ExportIcon /></SideButton>
                    }
                    {pages.audit &&
                        <SideButton history={history} path='/audit-records' text='Audit Records'><AuditRecordIcon /></SideButton>
                    }
                    {pages.admin &&
                        <SideButton history={history} path='/admin' text='Admin'><SettingsIcon /></SideButton>
                    }
                    {pages.system &&
                        <SideButton history={history} path='/system' text='System' ><SettingsIcon /></SideButton>
                    }
                </div>
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.topbar}>
                        <div style={{ flex: 1, marginLeft: '48px' }}>
                            <div ref={titleRef} >
                                <Route exact path='/' ><span className='title'>Classroom Manager</span></Route>
                            </div>
                        </div>
                        <div style={{ marginRight: '24px' }}>
                            <Account />
                        </div>
                    </div>
                    <div style={{ flex: '1', display: 'flex' }}>
                        {pages.noAccess !== true &&
                            <Switch>
                                {pages.classroom && <Route path='/classrooms'><Classrooms {...pageProps} /></Route>}
                                {pages.importExport && <Route path='/import-export'><ImportExport {...pageProps} /></Route>}
                                {pages.audit && <Route path='/audit-records'><AuditRecords {...pageProps} /></Route>}
                                {pages.admin && <Route path='/admin'><Admin {...pageProps} /></Route>}
                                {pages.system && <Route path='/system'><System {...pageProps} /></Route>}
                                <Route exact path='/' >
                                    <>
                                        <div>
                                            <div style={{ flex: 1, margin: '96px', display: 'flex', flexWrap: 'wrap' }}>
                                                {pages.classroom && <HomeCard history={history} path='/classrooms' icon={<ClassroomsIcon />} title="Classrooms"></HomeCard>}
                                                {pages.importExport && <HomeCard history={history} path='/import-export' icon={<ExportIcon />} title="Import export"></HomeCard>}
                                                {pages.audit && <HomeCard history={history} path='/audit-records' icon={<AuditRecordIconDark />} title="Audit Records"></HomeCard>}
                                                {pages.admin && <HomeCard history={history} path='/admin' icon={<SettingsIcon />} title="Admin"></HomeCard>}
                                                {pages.system && <HomeCard history={history} path='/system' icon={<SettingsIcon />} title="System"></HomeCard>}

                                            </div>
                                        </div>
                                    </>
                                </Route>
                            </Switch>
                        }
                        {pages.noAccess === true &&
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="paper" style={{ width: '600px', height: '400px' }}>
                                    <div>
                                        <div style={{ fontSize: '1.1rem', fontWeight: '500', color: '#555', paddingBottom: '8px', borderBottom: '1px solid #eee' }}>No access
                                        </div>
                                        <div>
                                            <p>You do not have access to Classroom Manager.</p>
                                            <p>If Classroom Manager is enabled for your domain, contact a Classroom Manager Administrator from your organization to be granted access.</p>
                                            <p>If Classroom Manager is not enabled for your domain, Contact Wizkids Sales or Support for further info.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
