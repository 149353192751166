import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles((theme) => ({
    noContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontStyle: 'italic',
        height: '100%'
    },
    scrollPanelContainer: {
        position: 'relative',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    scrollPanel: {
        position: 'absolute',
        width: '100%'
    },
    sidePanelBackdrop: {
        position: 'fixed',
        inset: '80px 0px 0px 130px',
        zIndex: 900,
        backgroundColor: 'rgb(0 0 0 / 25%)',
        display: 'flex'
    },
    sidePanel: {
        display: 'flex',
        flexDirection: 'column',
        width: '650px',
        backgroundColor: '#fff',
        boxShadow: 'rgb(0 0 0 / 10%) -10px 0 14px 0px'
    }
}));

function NoContent({ text }) {
    const classes = useStyles();
    return (
        <div className={classes.noContent}>
            <span>{text}</span>
        </div>
    )
}


function ScrollPanel({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.scrollPanelContainer}>
            <div className={classes.scrollPanel}>
                {children}
            </div>
        </div>
    )
}


function SidePanel({ title, children, onClose }) {
    const classes = useStyles();

    return (
        <>
            <div className={classes.sidePanelBackdrop} onClick={onClose}>
                <div style={{ flex: 1 }}></div>
                <div className={classes.sidePanel} onClick={e => e.stopPropagation()}>
                    <div style={{ padding: '16px 16px 16px 40px', backgroundColor: '#eee' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {title}
                            </div>
                            <div>
                                <Tooltip title="Close" placement="left-start">
                                    <IconButton onClick={onClose}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}


export { NoContent, ScrollPanel, SidePanel };
