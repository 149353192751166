import { Checkbox, IconButton, LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { NoContent } from "../../components/Misc";


const useStyles = makeStyles((theme) => ({

    header: {
        height: '48px',
        overflow: 'hidden',
        zIndex: 100,
        boxShadow: '0px 6px 8px -4px #00000033'
    },

    columnHeader: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRight: '2px solid #ccc'
    },

    sortableColumn: {
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: '#eee',
        }
    },

    row: {
        display: 'flex',
        borderTop: '1px solid #eee',
        borderBottom: '1px solid #eee',
        height: '56px',
        alignItems: 'center',
        '&:hover': {
            borderTop: '1px solid #ccc',
            borderBottom: '1px solid #ccc'
        }
    }
}));

function SelectHeader({ table }) {
    const { selectedCourseIds, onSelectedCourseIdsChange } = table.getState();
    const rows = table.getRowModel().rows;


    const allSelected = rows.length !== 0 && rows.every(x => selectedCourseIds.includes(x.id));
    const someSelected = rows.length !== 0 && rows.some(x => selectedCourseIds.includes(x.id)) && !allSelected;

    const handleSelectedChange = (value) => {
        if (value) {
            onSelectedCourseIdsChange(rows.map(x => x.id));
        } else {
            onSelectedCourseIdsChange([]);
        }
    }

    return (
        <Tooltip title="Select all" placement="bottom">
            <Checkbox checked={allSelected} indeterminate={someSelected} onChange={x => handleSelectedChange(x.target.checked)} color="primary" />
        </Tooltip>
    )
}

function SelectCell({ row, table }) {
    const { selectedCourseIds, onSelectedCourseIdsChange } = table.getState();
    const isSelected = selectedCourseIds.includes(row.id);

    const handleSelectedChange = (value) => {
        if (value) {
            onSelectedCourseIdsChange([...selectedCourseIds, row.id]);
        } else {
            onSelectedCourseIdsChange(selectedCourseIds.filter(x => x !== row.id));
        }
    }

    return (
        <Checkbox checked={isSelected} onChange={x => handleSelectedChange(x.target.checked)} color="primary" />
    )
}

function ActionCell({ row, table }) {
    return (
        <Tooltip title="Actions" placement="bottom">
            <IconButton className="fade" onClick={(e) => table.getState().openActionMenu(e.currentTarget, row.original)}><MoreVertIcon /></IconButton>
        </Tooltip>
    )
}

function CoursesTable({ courses, visibleColumns, sorting, onSortingChange, loading, openActionMenu, selectedCourseIds, onSelectedCourseIdsChange }) {

    const classes = useStyles();

    const columnDef = useMemo(() => [
        {
            id: 'select',
            size: 56,
            enableSorting: false,
            enableResizing: false,
            header: SelectHeader,
            cell: SelectCell
        },
        {
            id: 'name',
            accessorKey: 'nameMarked',
            minSize: 300,
            header: () => <span>Name</span>,
            cell: props => <span>{props.getValue()}</span>
        },
        {
            accessorKey: 'section',
            size: 300,
            header: () => <span>Section</span>,
            cell: props => <span>{props.getValue()}</span>
        },
        {
            id: 'created',
            accessorKey: 'createdFormatted',
            size: 180,
            header: () => <span>Created</span>,
            cell: props => <span>{props.getValue()}</span>
        },
        {
            id: 'updated',
            accessorKey: 'updatedFormatted',
            size: 180,
            header: () => <span>Updated</span>,
            cell: props => <span>{props.getValue()}</span>
        },
        {
            accessorKey: 'ownerEmail',
            size: 250,
            header: () => <span>Owner Email</span>,
            cell: props => <span>{props.getValue()}</span>
        },
        {
            accessorKey: 'ownerOrgUnitPath',
            size: 250,
            header: () => <span>Owner OrgUnit</span>,
            cell: props => <span>{props.getValue()}</span>
        },
        {
            id: 'actions',
            size: 64,
            enableSorting: false,
            enableResizing: false,
            header: () => <></>,
            cell: ActionCell
        }
    ], [])

    const getRowId = useCallback(x => x.id, []);

    const columnVisibility = useMemo(() => {
        return ["section", "created", "updated", "ownerEmail", "ownerOrgUnitPath"].reduce((x, columnId) => {
            x[columnId] = visibleColumns.includes(columnId);
            return x;
        }, {});
    }, [visibleColumns]);

    const [columnSizing, setColumnSizing] = useState({});

    const onSortingChangeCallback = useCallback((updateFn) => onSortingChange(updateFn(sorting)), [sorting, onSortingChange]);

    const table = useReactTable({
        data: courses,
        columns: columnDef,
        getRowId,
        manualSorting: true,
        isMultiSortEvent: () => false,
        columnResizeMode: 'onEnd',
        onSortingChange: onSortingChangeCallback,
        onColumnSizingChange: setColumnSizing,
        state: {
            selectedCourseIds,
            onSelectedCourseIdsChange,
            openActionMenu,
            columnVisibility,
            columnSizing,
            sorting
        },
        getCoreRowModel: getCoreRowModel(),
    });

    const headerRef = useRef(null);
    const tableSize = table.getTotalSize();
    const columns = table.getAllColumns();
    const { ref, width: tableContainerWidth } = useResizeDetector({ refreshMode: 'throttle', refreshRate: 200, refreshOptions: { trailing: true } });

    useEffect(() => {
        const availableSpace = tableContainerWidth - tableSize - 20;
        if (availableSpace > 0) {
            const resizableColumns = columns.filter(x => x.getIsVisible() && x.getCanResize());
            const lastResizableColumn = resizableColumns[resizableColumns.length - 1];
            const newSize = lastResizableColumn.getSize() + availableSpace;

            setColumnSizing(x => {
                const sizing = { ...x };
                sizing[lastResizableColumn.id] = newSize;
                return sizing;
            });
        }
    }, [columns, tableSize, tableContainerWidth]);

    const headers = table.getFlatHeaders();

    return (
        <div ref={ref} style={{ flex: 1, position: 'relative' }}>
            <div style={{ width: '100%', height: '100%', position: 'absolute', display: 'flex', flexDirection: 'column' }}>
                <div ref={headerRef} className={classes.header}>
                    <div style={{ display: 'flex', width: table.getTotalSize() + 20, height: '100%' }} >
                        {headers.map((header, index) =>
                            <div key={header.id} style={{ width: header.getSize() + ((index === headers.length - 1) ? 20 : 0), height: '100%', position: 'relative' }} >
                                <div {...{
                                    onClick: header.column.getToggleSortingHandler(),
                                    className: `${classes.columnHeader}  ${header.column.getCanSort() ? classes.sortableColumn : ""}`,
                                    style: (index === headers.length - 1) ? { borderRight: 0 } : {}
                                }}>
                                    <div style={{ flex: 1, paddingLeft: '8px' }}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </div>
                                    <div style={{ paddingRight: '8px' }}>
                                        {header.column.getIsSorted() === 'asc' && <ArrowDownwardIcon />}
                                        {header.column.getIsSorted() === 'desc' && <ArrowUpwardIcon />}
                                    </div>
                                </div>
                                {header.column.getCanResize() &&
                                    <div
                                        onMouseDown={header.getResizeHandler()}
                                        onTouchStart={header.getResizeHandler()}
                                        className={`column-resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                                        style={{ transform: header.column.getIsResizing() ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)` : '', }}
                                    />
                                }
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ flex: 1, overflow: 'auto', backgroundColor: '#fff', display: 'flex', flexDirection: 'column' }} onScroll={e => { headerRef.current.scrollLeft = e.target.scrollLeft }}>
                    <div style={{ flex: 1, width: table.getTotalSize() }}>
                        {loading && <LinearProgress />}
                        {!loading && courses.length === 0 && <NoContent text="No Classrooms found." />}
                        {!loading && courses.length !== 0 && table.getRowModel().rows.map(row => (
                            <div key={row.id} className={classes.row + " row-fade"} >
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="ellipsis" style={{ flexShrink: 0, width: cell.column.getSize(), paddingLeft: '8px' }}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoursesTable;
