import { createContext, useContext, useMemo, useState } from "react";

const AppStateContext = createContext({});

function AppState({ children }) {

    const [appError, setAppError] = useState(null);
    const [account, setAccount] = useState(null);
    const [impersonateCustomer, setImpersonateCustomer] = useState(null);
    const [courseListParams, setCourseListParams] = useState({
        nameFilter: '',
        participantFilter: null,
        domainFilter: 'any',
        createdAfterFilter: null,
        createdBeforeFilter: null,
        updatedAfterFilter: null,
        updatedBeforeFilter: null,
        courseIdFilter: null,
        courseStateFilter: 'ACTIVE',
        pageSize: 20,
        pageNumber: 1
    });

    const [courseListColumns, setCourseListColumns] = useState({
        columns: ['name', 'section'],
        sorting: []
    });

    const appStateContextValue = useMemo(() => ({
        appError,
        setAppError,
        account,
        setAccount,
        impersonateCustomer,
        setImpersonateCustomer,
        courseListParams,
        setCourseListParams,
        courseListColumns,
        setCourseListColumns
    }), [appError, account, impersonateCustomer, courseListParams, courseListColumns]);

    return (
        <AppStateContext.Provider value={appStateContextValue}>
            {children}
        </AppStateContext.Provider>
    );
}

function useAppError() {
    const { appError, setAppError } = useContext(AppStateContext);
    return { appError, setAppError };
}

function useAccount() {
    const { account, setAccount } = useContext(AppStateContext);
    return { account, setAccount };
}

function useImpersonateCustomer() {
    const { impersonateCustomer, setImpersonateCustomer } = useContext(AppStateContext);
    return { impersonateCustomer, setImpersonateCustomer };
}

function useCourseListParams() {
    const { courseListParams, setCourseListParams } = useContext(AppStateContext);

    const setNameFilter = (x) => setCourseListParams(p => ({ ...p, nameFilter: x, pageNumber: 1 }));
    const setParticipantFilter = (x) => setCourseListParams(p => ({ ...p, participantFilter: x, pageNumber: 1 }));
    const setDomainFilter = (x) => setCourseListParams(p => ({ ...p, domainFilter: x, pageNumber: 1 }));
    const setCreatedAfterFilter = (x) => setCourseListParams(p => ({ ...p, createdAfterFilter: x, pageNumber: 1 }));
    const setCreatedBeforeFilter = (x) => setCourseListParams(p => ({ ...p, createdBeforeFilter: x, pageNumber: 1 }));
    const setUpdatedAfterFilter = (x) => setCourseListParams(p => ({ ...p, updatedAfterFilter: x, pageNumber: 1 }));
    const setUpdatedBeforeFilter = (x) => setCourseListParams(p => ({ ...p, updatedBeforeFilter: x, pageNumber: 1 }));
    const setCourseIdFilter = (x) => setCourseListParams(p => ({ ...p, courseIdFilter: x, pageNumber: 1 }));
    const setCourseStateFilter = (x) => setCourseListParams(p => ({ ...p, courseStateFilter: x, pageNumber: 1 }));
    const setPageNumber = (x) => setCourseListParams(p => ({ ...p, pageNumber: x }));
    const setPageSize = (x) => setCourseListParams(p => ({ ...p, pageSize: x, pageNumber: 1 }));

    const result = {
        params: courseListParams,
        setNameFilter,
        setParticipantFilter,
        setDomainFilter,
        setCreatedAfterFilter,
        setCreatedBeforeFilter,
        setUpdatedAfterFilter,
        setUpdatedBeforeFilter,
        setCourseIdFilter,
        setCourseStateFilter,
        setPageNumber,
        setPageSize
    };

    return result;
}

function useCourseListColumns(){
    const { courseListColumns, setCourseListColumns,setCourseListParams } = useContext(AppStateContext);

    const setColumns = (x) => {
        setCourseListColumns(c => ({ ...c, columns: x }));
    };
    const setSorting = (x) => {
        setCourseListColumns(c => ({ ...c, sorting: x }));
        setCourseListParams(p => ({ ...p, pageNumber: 1 }));
    };

    const result = {
        columns: courseListColumns.columns,
        sorting: courseListColumns.sorting,
        setColumns,
        setSorting
    }

    return result;
}

export { AppState, useAppError, useAccount, useImpersonateCustomer, useCourseListParams, useCourseListColumns }
