import { makeStyles, Portal } from "@material-ui/core";
import { useState } from "react";
import AppUsers from "./AppUsers";
import Misc from "./Misc";
import Sections from "./Sections";

const useStyles = makeStyles((theme) => ({
    tabButtonSelected: {
        padding: '16px',
        color: '#fff',
        backgroundColor: '#002c64',
        fontWeight: '500'
    },
    tabButton: {
        cursor: 'pointer',
        padding: '16px',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
}));

function TabButton({ selected, label, onClick }) {
    const classes = useStyles();

    return (
        <div>
            {selected === true &&
                <div className={classes.tabButtonSelected}>
                    <span>{label}</span>
                </div>
            }
            {selected === false &&
                <div onClick={onClick} className={classes.tabButton}>
                    <span>{label}</span>
                </div>
            }
        </div>
    )
}


function Admin({ titleRef }) {

    const [activeTab, setActiveTab] = useState('users');

    return (
        <>
            <Portal container={titleRef.current}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="title" style={{ flex: 1 }}>
                        <span>Admin</span>
                    </div>
                </div>
            </Portal>
            <div style={{ flex: 1, display: 'flex', margin: '16px' }}>
                <div style={{ width: '300px', marginRight: '16px' }}>
                    <div className="paper">
                        <TabButton selected={activeTab === 'users'} onClick={() => setActiveTab('users')} label="Users" />
                        <TabButton selected={activeTab === 'sections'} onClick={() => setActiveTab('sections')} label="Sections" />
                        <TabButton selected={activeTab === 'misc'} onClick={() => setActiveTab('misc')} label="Misc" />
                    </div>
                </div>

                <div className="paper" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {activeTab === 'users' &&
                        <AppUsers />
                    }
                    {activeTab === 'sections' &&
                        <Sections />
                    }
                    {activeTab === 'misc' &&
                        <Misc />
                    }
                </div>
            </div>
        </>
    )
}

export default Admin;
