import { IconButton, makeStyles, Snackbar, SnackbarContent } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useAppError } from "./AppState";

const useStyles = makeStyles((theme) => ({
    appError: {
        backgroundColor: '#f44336;',
    }
}))

function AppError() {

    const classes = useStyles();
    const { appError, setAppError } = useAppError();

    return (
        <Snackbar open={appError !== null} autoHideDuration={6000} onClose={() => setAppError(null)} >
            <SnackbarContent classes={{ root: classes.appError }} message={appError} action={
                <IconButton size="small" color="inherit" onClick={() => setAppError(null)}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            } />
        </Snackbar>
    )
}

export default AppError;