import { Button, Checkbox, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, List, ListItem, MenuItem, Paper, Popover, Select, TextField, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker } from "@material-ui/pickers";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { format } from 'date-fns'

import { useCallback, useEffect, useRef, useState } from 'react';
import api from "../../api";
import { makeStyles } from '@material-ui/styles';
import { NoContent, ScrollPanel } from '../../components/Misc';
import { useAppError } from '../../components/AppState';

const useStyles = makeStyles((theme) => ({
    courseItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#eee',
            borderBottom: '1px solid #555'
        }
    }
}));

function ClearButton({ visible, onClick, ...props }) {
    return (
        <InputAdornment {...props} position="end">
            {visible === true &&
                <IconButton onClick={e => { e.stopPropagation(); onClick(); }}>
                    <ClearIcon />
                </IconButton>
            }
        </InputAdornment>
    )
}

function CourseFilters({ onChange }) {

    const debounceTimerRef = useRef(null);
    const [nameValue, setNameValue] = useState('');
    const [name, setName] = useState('');
    const [createdAfter, setCreatedAfter] = useState(null);
    const [createdBefore, setCreatedBefore] = useState(null);
    const [updatedAfter, setUpdatedAfter] = useState(null);
    const [updatedBefore, setUpdatedBefore] = useState(null);
    const [courseState, setCourseState] = useState('any');
    const [domain, setDomain] = useState('any');
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        onChange({
            name,
            createdAfter,
            createdBefore,
            updatedAfter,
            updatedBefore,
            courseState,
            domain
        });
    }, [name, createdAfter, createdBefore, updatedAfter, updatedBefore, courseState, domain, onChange]);

    useEffect(() => {
        api.getDomains().then(x => {
            setDomains(x.data);
        }, () => { })
    }, []);

    const handleNameValueChange = (x) => {
        setNameValue(x);
        clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = setTimeout(() => setName(x), 300);
    }

    const handleCreatedAfterChange = (x) => setCreatedAfter(x === null ? null : format(x, 'yyyy-MM-dd'));
    const handleCreatedBeforeChange = (x) => setCreatedBefore(x === null ? null : format(x, 'yyyy-MM-dd'));
    const handleUpdatedAfterChange = (x) => setUpdatedAfter(x === null ? null : format(x, 'yyyy-MM-dd'));
    const handleUpdatedBeforeChange = (x) => setUpdatedBefore(x === null ? null : format(x, 'yyyy-MM-dd'));


    return (
        <div>
            <div style={{ marginBottom: '24px' }}>
                <TextField fullWidth label="Classroom Name"
                    value={nameValue}
                    onChange={x => handleNameValueChange(x.target.value)}
                    InputProps={{ endAdornment: <ClearButton visible={nameValue !== ''} onClick={() => { setNameValue(''); setName(''); }} /> }}
                />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Created after" variant="inline" value={createdAfter} onChange={handleCreatedAfterChange}
                    InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={createdAfter !== null} onClick={() => handleCreatedAfterChange(null)} /> }}
                />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Created before" variant="inline" value={createdBefore} onChange={handleCreatedBeforeChange}
                    InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={createdBefore !== null} onClick={() => handleCreatedBeforeChange(null)} /> }}
                />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Updated after" variant="inline" value={updatedAfter} onChange={handleUpdatedAfterChange}
                    InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={updatedAfter !== null} onClick={() => handleUpdatedAfterChange(null)} /> }}
                />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <DatePicker fullWidth disableFuture format="yyyy/MM/dd" label="Updated before" variant="inline" value={updatedBefore} onChange={handleUpdatedBeforeChange}
                    InputProps={{ endAdornment: <ClearButton style={{ marginLeft: '-48px' }} visible={updatedBefore !== null} onClick={() => handleUpdatedBeforeChange(null)} /> }}
                />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <FormControl fullWidth>
                    <InputLabel>Classroom State</InputLabel>
                    <Select value={courseState} onChange={x => setCourseState(x.target.value)}>
                        <MenuItem value={'any'}>Any</MenuItem>
                        <MenuItem value={'ACTIVE'}>Active</MenuItem>
                        <MenuItem value={'ARCHIVED'}>Archived</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {(domains.length > 2) &&
                <div>
                    <FormControl fullWidth>
                        <InputLabel>Domain</InputLabel>
                        <Select value={domain} onChange={x => setDomain(x.target.value)}>
                            <MenuItem value={'any'}>Any</MenuItem>
                            {domains.map((x, i) =>
                                <MenuItem key={i} value={x}>{x}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            }
        </div>
    )
}

function CourseSelection({ onCourseSelected }) {

    const { setAppError } = useAppError();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [allCourseCount, setAllCourseCount] = useState(0);
    const [selectedCourseIds, setSelectedCourseIds] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const pageCount = Math.ceil(allCourseCount / pageSize);
    const [filters, setFilters] = useState(null);

    const pageSizeMenuAnchorRef = useRef(null);
    const [pageSizeMenuOpen, setPageSizeMenuOpen] = useState(false);

    useEffect(() => {
        if (filters) {
            const params = {
                offset: (pageNumber - 1) * pageSize,
                limit: pageSize
            };

            if (filters.name !== '') {
                params.name = filters.name;
            }
            if (filters.courseState !== 'any') {
                params.courseState = filters.courseState;
            }
            if (filters.domain !== 'any') {
                params.domain = filters.domain;
            }
            if (filters.createdAfter !== null) {
                params.createdAfter = filters.createdAfter;
            }
            if (filters.createdBefore !== null) {
                params.createdBefore = filters.createdBefore;
            }
            if (filters.updatedAfter !== null) {
                params.updatedAfter = filters.updatedAfter;
            }
            if (filters.updatedBefore !== null) {
                params.updatedBefore = filters.updatedBefore
            }

            setLoading(true);
            api.getCourses(params).then(x => {
                setCourses(x.data.courses);
                setAllCourseCount(x.data.allCount);
                setLoading(false);
            }, () => {
                setLoading(false);
                setAppError("Failed getting Classrooms.");
            });
        }
    }, [filters, pageNumber, pageSize, setAppError]);

    const handleFilterChange = useCallback(x => {
        setFilters(x);
        setPageNumber(1);
    }, [setFilters, setPageNumber])

    const handleSelectAllClick = () => {
        if (allSelected) {
            setSelectedCourseIds([]);
        } else {
            setSelectedCourseIds(courses.map(x => x.id));
        }
    }

    const handleSelectCourse = (course, checked) => {
        if (checked) {
            setSelectedCourseIds(c => [...c, course.id]);
        } else {
            setSelectedCourseIds(c => c.filter(x => x !== course.id));
        }
    }

    const allSelected = courses.length !== 0 && courses.every(x => selectedCourseIds.includes(x.id));

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ fontSize: '1.1rem', paddingLeft: '16px' }}>
                    Export - classroom selection
                </div>
            </div>

            <div style={{ flex: 1, display: 'flex' }} >
                <div style={{ display: 'flex', flexDirection: 'column', width: '300px', marginTop: '8px', marginRight: '16px', paddingRight: '16px', borderRight: '1px solid #eee' }}>
                    <div style={{ flex: 1 }}>
                        <div>
                            <CourseFilters onChange={handleFilterChange} />
                        </div>
                        <div style={{ borderBottom: '1px solid #eee', margin: '32px' }}></div>
                        {selectedCourseIds.length !== 0 &&
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>Classrooms selected</div>
                                    <div>{selectedCourseIds.length}</div>
                                </div>
                                <div style={{ borderBottom: '1px solid #eee', margin: '32px' }}></div>
                                <div>
                                    <Button fullWidth variant='contained' endIcon={<ClearIcon />} onClick={() => setSelectedCourseIds([])} >Clear selection</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '52px', paddingBottom: '8px', borderBottom: '1px solid #eee' }}>
                        <div>
                            {courses.length !== 0 &&
                                <Tooltip title="Select all" placement="bottom">
                                    <Checkbox checked={allSelected} onClick={() => handleSelectAllClick()} color="primary" />
                                </Tooltip>
                            }
                        </div>
                        <div style={{ flex: 1 }}></div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {loading &&
                            <LinearProgress />
                        }
                        {!loading && courses.length === 0 &&
                            <NoContent text="No classrooms found." />
                        }
                        {!loading && courses.length !== 0 &&
                            <ScrollPanel>
                                {courses.map(x =>
                                    <div key={x.id} className={classes.courseItem}>
                                        <div>
                                            <Checkbox color="primary" checked={selectedCourseIds.includes(x.id)} onChange={e => handleSelectCourse(x, e.target.checked)} />
                                        </div>
                                        <div style={{ flex: 1 }}>{x.name}</div>
                                        <div style={{ width: '150px' }}>
                                            {x.assignedSystemManaged && <CheckCircleIcon />}
                                        </div>
                                        <div style={{ width: '110px' }}>
                                            {x.assigned && <CheckCircleIcon />}
                                        </div>
                                    </div>
                                )}
                            </ScrollPanel>
                        }
                    </div>

                    <div style={{ borderTop: '1px solid #ccc', paddingTop: '8px', display: 'flex', height: '50px', alignItems: 'center' }} >
                        <div>Items per page:</div>
                        <div ref={pageSizeMenuAnchorRef}>
                            <Button onClick={() => setPageSizeMenuOpen(true)}><span style={{ fontWeight: 400 }}>{pageSize}</span><ArrowDropDownIcon /></Button>
                        </div>
                        <Popover
                            id="page-size-menu"
                            open={pageSizeMenuOpen}
                            anchorEl={pageSizeMenuAnchorRef.current}
                            onClose={() => { setPageSizeMenuOpen(false) }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                            transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                        >
                            <Paper >
                                <List>
                                    {[10, 20, 50, 100].map((x, index) =>
                                        <ListItem key={index} button onClick={() => { setPageSizeMenuOpen(false); setPageSize(x); }}>{x}</ListItem>
                                    )}
                                </List>
                            </Paper>
                        </Popover>
                        <div style={{ flex: 1 }}></div>

                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(1)}><FirstPageIcon /></IconButton>
                        <div>Page {pageNumber} of {pageCount}</div>
                        <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><NavigateBeforeIcon /></IconButton>
                        <IconButton disabled={pageNumber + 1 > pageCount} onClick={() => setPageNumber(pageNumber + 1)}><NavigateNextIcon /></IconButton>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                </div>
                <div>
                    <Button color="primary" variant='contained' disabled={selectedCourseIds.length === 0} endIcon={<ChevronRightIcon />} onClick={() => onCourseSelected(selectedCourseIds)} >Next</Button>
                </div>
            </div>
        </div>
    );
}

function FormatAndExportButton({ onClick }) {
    const [exportFormat, setExportFormat] = useState('csv');
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '130px', marginRight: '16px', marginTop: '-16px' }}>
                <FormControl fullWidth>
                    <InputLabel >Export format</InputLabel>
                    <Select value={exportFormat} onChange={x => setExportFormat(x.target.value)}>
                        <MenuItem value={'csv'}>csv</MenuItem>
                        <MenuItem value={'xlsx'}>xlsx</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Button color="primary" variant='contained' startIcon={<GetAppIcon />} onClick={() => onClick(exportFormat)} >Export</Button>
        </div>
    );
}

function ExportDetails({ courseIds, onDone }) {
    const { setAppError } = useAppError();
    const [loading, setLoading] = useState(false);

    const cancelExport = useRef(false);

    useEffect(() => {
        return () => {
            cancelExport.current = true;
        }
    }, []);

    const handleExportCourseDetails = (format) => {
        setLoading(true);
        api.generateCourseDetailsExport({ courseIds, format }).then(x => {
            setLoading(false);
            if (!cancelExport.current) {
                window.location = `api/files/${x.data.token}/${x.data.name}`;
            }
            onDone();
        }, () => {
            setLoading(true);
            setAppError("Failed generating export");
        });
    }

    const handleExportCourseParticipants = (format) => {
        setLoading(true);
        api.generateCourseParticipantExport({ courseIds, format }).then(x => {
            setLoading(false);
            if (!cancelExport.current) {
                window.location = `api/files/${x.data.token}/${x.data.name}`;
            }
            onDone();
        }, () => {
            setLoading(true);
            setAppError("Failed generating export");
        });
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {loading &&
                <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: '16px' }}>
                        <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Generating export</div>
                    </div>
                    <LinearProgress />
                </div>
            }
            {!loading &&
                <>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: '16px' }}>
                            <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Export classroom details</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            The details of the selected classrooms will be exported. <br></br>
                            This includes the fields: classroom id, state, name, section, owner email, url, created date, updated date.
                        </div>
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                            <div style={{ flex: 1 }}></div>
                            <div>
                                <FormatAndExportButton onClick={handleExportCourseDetails} />
                            </div>
                        </div>
                    </div>

                    <div style={{ margin: '16px', borderBottom: '1px solid #ccc' }}></div>

                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: '16px' }}>
                            <div style={{ fontSize: '1.1rem', marginLeft: '16px' }}>Export classroom participants</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            The participant list (teachers and students) of the selected classrooms will be exported. <br></br>
                            This export can be used as a base for the Import - Synchronize classroom participants.
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}></div>
                            <div>
                                <FormatAndExportButton onClick={handleExportCourseParticipants} />
                            </div>
                        </div>
                    </div>
                </>
            }
            <div style={{ margin: '16px', borderBottom: '1px solid #ccc' }}></div>
            <div>
                <Button variant='contained' onClick={onDone} >Cancel</Button>
            </div>
        </div>
    )
}

function Export() {

    const [courseIds, setCourseIds] = useState(null);

    return (
        <>
            {courseIds === null &&
                <CourseSelection onCourseSelected={setCourseIds} />
            }
            {courseIds !== null &&
                <ExportDetails courseIds={courseIds} onDone={() => setCourseIds(null)} />
            }
        </>
    );
}

export default Export;
